import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { Chip, Stack, Typography, Box} from "@mui/material";
import { ListCaption, ListTitle } from "../../../common/Styled/CommonStyled";
import {
  BoxTitle,
  CardBoxViewer,
  ChatBox,
  ChipBox,
  ListBox,
  SpeechBubble,
  Root,
  StickyTitle,
  InputSearch
} from "./Styled/ConversationContentDetailComponentStyled";
import { WordListBox, WordList } from "../Pronunciation/Styled/PronunciationListDetailStyled";
import { inject, observer } from "mobx-react";
import CommonLoading from "../../CommonComponent/CommonLoading";
import { DATE_UTIL } from "../../../common/util/date.util";
import { Icon } from '@iconify/react';



function ConversationContentDetailComponent(props) {
  const {intl, authStore, userStore, conversationStore} = props;
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [colorMapping, setColorMapping] = useState({});
  const [highlightIndex, setHighlightIndex] = useState(-1);

  useEffect(() => {
    const scrollToElement = () => {
      if (conversationStore.topScrollMessage !== '') {
        const messageElements = document.getElementsByTagName('span');
        for (let i = 0; i < messageElements.length; i++) {
          if (messageElements[i].innerText === conversationStore.topScrollMessage) {
            messageElements[i].scrollIntoView({ behavior: 'smooth' });
            conversationStore.setTopScrollMessage('');
            break;
          }
        }
      }
    }
    setTimeout(scrollToElement, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let intervalId;

    if (conversationStore.conversation.startDatetime === null) {
      intervalId = setInterval(() => {
        conversationStore.getLatestRealTimeConversation(
          authStore.loginUser.id,
          userStore.selectedDevice.deviceId,
          conversationStore.conversation.dialogId
        );
      }, 2000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationStore.conversation]);

  useEffect(() => {
    if (conversationStore.conversation.topicWordList) {
      const newColorMapping = {};
      const availableColors = ['#305580', '#B79470', '#FCB100', '#9277BF', '#5CBA47', '#DE6F9E', '#DE765F', '#90CBFB', '#FEA443'];

      conversationStore.conversation.topicWordList.forEach(word => {
        const randomIndex = Math.floor(Math.random() * availableColors.length);
        newColorMapping[word] = availableColors[randomIndex];
        availableColors.splice(randomIndex, 1);
      });

      setColorMapping(newColorMapping);
    }
  }, [conversationStore.conversation.topicWordList]);

  const getDate = (dialogStartDatetime) => {
    return DATE_UTIL.convertUTCToTimeZone(dialogStartDatetime, 'YYYY.MM.DD A hh:mm');
  }

  const getDuration = (duration) => {
    if (duration < 60) {
      if (duration < 1) {
        duration = 1;
      }
      return duration + ` ${intl.formatMessage({ id: "seconds" })}`;
    }

    let hours = Math.floor(duration / 3600);
    let minutes = Math.floor((duration % 3600) / 60);

    let timeString = '';

    if (hours > 0) {
      timeString += hours + ` ${intl.formatMessage({ id: "hour" })} `;
    }

    if (minutes > 0) {
      timeString += minutes + ` ${intl.formatMessage({ id: "minute" })} `;
    }

    return timeString.trim();
  }
  const handleClickMoveList = () => {
    conversationStore.setType('list');
  }

  const handleSearchClick = () => {
    setIsSearchActive(true);
  }

  const handleCancelClick = () => {
    setIsSearchActive(false);
    setSearchTerm('');
  }

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  }

  const highlightText = (text) => {
    if (!searchTerm) return text;

    const parts = text.split(new RegExp(`(${searchTerm})`, 'gi'));
    return parts.map((part, index) => (
      part.toLowerCase() === searchTerm.toLowerCase() ? <span key={index} className="highlight">{part}</span> : part
    ));
  }

  const highlightTitle = (text) => {
    if (!searchTerm) return text;

    const parts = text.split(new RegExp(`(${searchTerm})`, 'gi'));
    return parts.map((part, index) => (
        part.toLowerCase() === searchTerm.toLowerCase() ? <span key={index} className="highlight" style={{fontWeight: 'bold', fontSize: '1rem'}}>{part}</span> : part
    ));
  }

  useEffect(() => {
    if (searchTerm) {
      setHighlightIndex(0);
      scrollToHighlight(0);
    }
  }, [searchTerm]);

  const scrollToHighlight = (index) => {
    const highlights = document.querySelectorAll(".highlight");
    if (highlights.length > 0 && index >= 0 && index < highlights.length) {
      highlights.forEach((highlight, idx) => {
        highlight.style.backgroundColor = "yellow";
        highlight.style.color = idx === index ? "red" : "inherit";
      });
      highlights[index].scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  const handleNextHighlight = () => {
    const highlights = document.querySelectorAll(".highlight");
    if (highlights.length > 0) {
      const newIndex = (highlightIndex + 1) % highlights.length;
      setHighlightIndex(newIndex);
      scrollToHighlight(newIndex);
    }
  };

  const handlePrevHighlight = () => {
    const highlights = document.querySelectorAll(".highlight");
    if (highlights.length > 0) {
      const newIndex = (highlightIndex - 1 + highlights.length) % highlights.length;
      setHighlightIndex(newIndex);
      scrollToHighlight(newIndex);
    }
  };

  return (
    <>
      <StickyTitle mt={1}>
        <Icon icon="material-symbols:arrow-back-ios-rounded" onClick={handleClickMoveList} />
        {isSearchActive ? (
          <Stack flexDirection="row" alignItems="center" gap={0.5}>
            <InputSearch
              placeholder={intl.formatMessage({ id: "search" })}
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <Icon icon="iconamoon:arrow-up-2-light" onClick={handlePrevHighlight} />
            <Icon icon="iconamoon:arrow-down-2-light" onClick={handleNextHighlight} />
            <Icon icon="material-symbols:close" onClick={handleCancelClick} style={{marginLeft:'8'}}/>
          </Stack>
        ) : (
          <Icon icon="mingcute:search-line" onClick={handleSearchClick} />
        )}
      </StickyTitle>

      
      <Root sx={{paddingTop:0}}>
        <React.Fragment>
          {conversationStore.isConversationLoading ?
            <div style={{marginTop: '30%'}}>
              <CommonLoading text={`${intl.formatMessage({ id: "loading_message" })}.`} />
            </div>
            :
            <React.Fragment>
              {conversationStore.conversation !== '' &&
                  <Box mb={3}>
                    <CardBoxViewer>
                      <ChipBox>
                        {conversationStore.conversation.topicWordList?.map((word, index) => (
                          <Chip key={index} label={word} style={{ backgroundColor: colorMapping[word] }} />
                        ))}
                      </ChipBox>
                      <BoxTitle>
                        <ListTitle variant="subtitle2">
                          {highlightTitle(conversationStore.conversation.firstMessage)}
                        </ListTitle>
                        {conversationStore.conversation.startDatetime !== null ?
                            <ListCaption>
                              {getDate(conversationStore.conversation.startDatetime)}  /  {getDuration(conversationStore.conversation.duration)}
                            </ListCaption>
                            :
                            <ListCaption>{intl.formatMessage({ id: "in_conversation" })}</ListCaption>
                        }
                      </BoxTitle>
                      <ListBox>
                        <ul style={{listStyle:'initial'}}>
                          {conversationStore.conversation.summaryList?.map((summary, index) => (
                              <li key={index}>{highlightText(summary)}</li>
                          ))}
                        </ul>
                      </ListBox>
                    </CardBoxViewer>

                    <ChatBox>
                      {conversationStore.conversation.dialogList.map((dialog, index) => {
                        if (dialog.role === 'user') {
                          return (
                              <SpeechBubble key={index} className='answer'>
                                <Stack maxWidth='80%'>
                                  <Box className="bubble">{highlightText(dialog.message)}</Box>
                                  {dialog.mode === 'Pronunciation' &&
                                    <WordListBox mt={1}>
                                      {dialog.pronunciation?.wordList.map((word, index) => (
                                          <WordList key={index} mr={2.5}>
                                            <Typography variant="body1" color={word.score <= 45 ? "#fe536b": null}>{word.word}</Typography>
                                            <Typography variant="caption">
                                              {word.phonemeList.map((phoneme, index) => (
                                                  <span key={index}>
                                              <span style={{ color: phoneme.score <= 45 ? "#fe536b" : null }}>{phoneme.phoneme}</span>
                                                    {index !== word.phonemeList.length - 1 && "・"}
                                            </span>
                                              ))}
                                            </Typography>
                                            <Typography variant="caption" style={{ display: "block" }}>
                                              {word.phonemeList.map((phoneme, index) => (
                                                  <span key={index}>
                                                <span style={{ color: phoneme.score <= 45 ? "#fe536b" : null }}>{phoneme.score}</span>
                                                    {index !== word.phonemeList.length - 1 && "・"}
                                              </span>
                                              ))}
                                            </Typography>
                                          </WordList>
                                      ))}
                                    </WordListBox>
                                  }
                                </Stack>
                              </SpeechBubble>
                          )
                        } else {
                          return (
                              <SpeechBubble key={index} className='question'>
                                <Box className="bubble">{highlightText(dialog.message)}</Box>
                              </SpeechBubble>
                          )
                        }
                      })}
                    </ChatBox>                      
                  </Box>
              }
            </React.Fragment>
          }
        </React.Fragment>
      </Root>
    </>
  );
}

export default injectIntl(inject('authStore', 'userStore', 'conversationStore')(observer(ConversationContentDetailComponent)));