import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const BoxIn = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: 'center',
  boxSizing: "border-box",
  padding: "24px 16px",
  borderRadius:16,
  width: '320px',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  "& img": {
    position:'absolute',
    width:40,
    right:20,
    top: 0
  },
}));

export const Loader = styled('span')(() => ({
  position:'relative',
  width : 48,
  height: 48,
  borderRadius: '50%',
  display: 'inline-block',
  border: '5px solid #F0F1F5',
  marginBottom:32,
  boxSizing:'border-box',
  '& .spinner':{
    position: 'absolute',
    top: -5,
    left: -5,
    width: 48,
    height: 48,
  },
  '& .path': {
    position:'absolute',
    top:0,
    left:0,
    stroke: '#FDD751',
    strokeLinecap: 'round',
    animation: `dash 1s ease-in-out infinite`,
  },
  '@keyframes rotate': {
    '0%': { transform: 'rotate(0deg)' },
    '100%' :{transform: 'rotate(360deg)'},
  },
  '@keyframes dash':{
    '0%': {
      strokeDasharray: '1, 150',
      strokeDashoffset: '0',
    },
    '50%': {
      strokeDasharray: '90, 150',
      strokeDashoffset: -35,
    },
    '100%': {
      strokeDasharray: '90, 150',
      strokeDashoffset: -124,
    }
  }
}));