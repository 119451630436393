import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { useLocation } from "react-router";
import { inject, observer } from "mobx-react";
import { Box, Typography, Stack } from "@mui/material";
import { Icon } from "@iconify/react";
import { DATE_UTIL } from "../../common/util/date.util";
import { COMMON_UTIL } from "../../common/util/common.util";
/* calendar */
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
/* styled */
import { Root } from "../../common/Styled/CommonStyled";
import {
  MoreButton,
  Container,
  TodaySituation,
  DateBox,
  BoxWrap,
  StatusBox,
  StatusText,
  StatusValue,
} from "./Styled/HomeStyled";
import { CalendarBox } from "../../common/Styled/CommonStyled";
/* conponent */
import BottomNavigation from "./BottomNavigation";
import Header from "./Header";
import BarChartComponent from "../CommonComponent/Chart/BarChart";
import WordCloudComponent from "../CommonComponent/Chart/WordCloud";
import SecretPokoro from "../../common/Images/SecretPokoro.png";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";

import { isHome } from "../../nativebridge/JsToNative";

function Home(props) {
  const history = useHistory();
  const [tabValue, setTabValue] = useState("board");
  const {
    intl,
    authStore,
    userStore,
    homeStore,
    loadingStore,
    pronunciationStore,
    reportStore,
    languageStore,
  } = props;
  const location = useLocation();
  const view = "daily";

  //항상 페이지 상단
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    loadingStore.setIsLoading(userStore.getIsLoading || homeStore.getIsLoading);
  }, [userStore.getIsLoading, homeStore.getIsLoading, loadingStore]);

  useEffect(() => {
    userStore.getUserDeviceList(authStore.loginUser.id, userStore.selectedDevice === "", (userId, deviceId) =>
        homeStore.getCalendar(userId, deviceId, (userId, deviceId) =>
            homeStore.getHomeData(userId, deviceId)
        )
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const buttons = document.querySelectorAll(
      ".react-calendar__navigation__label"
    );
    buttons.forEach((button) => {
      button.disabled = true;
      button.style.backgroundColor = "white";
      button.style.color = "black";
    });
  }, []);

  useEffect(() => {
    isHome(true);
    return () => {
      isHome(false);
    };
  }, []);

  // Function to add a className to specific dates
  const tileClassName = ({ date, view }) => {
    if (
      view === "month" &&
      homeStore.calendarDateList.some((d) => {
        const dateParts = d.split("-");
        return (
          new Date(
            dateParts[0],
            dateParts[1] - 1,
            dateParts[2],
            0,
            0,
            0
          ).getTime() === date.getTime()
        );
      })
    ) {
      return "act";
    }
    return null;
  };

  // Function to display an icon for specific dates
  const tileContent = ({ date, view }) => {
    if (
      view === "month" &&
      homeStore.calendarDateList.some((d) => {
        const dateParts = d.split("-");
        return (
          new Date(
            dateParts[0],
            dateParts[1] - 1,
            dateParts[2],
            0,
            0,
            0
          ).getTime() === date.getTime()
        );
      })
    ) {
      return (
        <img
          src={SecretPokoro}
          alt=""
          style={{ width: "33px", height: "33px" }}
          className="pokoro-icon"
        />
      );
    }
    return null;
  };

  const handleChangeActiveDate = (event) => {
    const activeStartDate = event.activeStartDate;

    homeStore.setSelectedCalendarYear(activeStartDate.getFullYear());
    homeStore.setSelectedCalendarMonth(activeStartDate.getMonth() + 1);

    homeStore.setIsChangeCalendar(true);

    if (authStore.selectedDevice !== "") {
      homeStore.getCalendar(
        authStore.loginUser.id,
        userStore.selectedDevice.deviceId
      );
    }
  };

  const handleChangeDay = (event) => {
    homeStore.setSelectedCalendarDate(event);
    homeStore.getHomeData(
      authStore.loginUser.id,
      userStore.selectedDevice.deviceId
    );

    pronunciationStore.setDate(dayjs(homeStore.selectedCalendarDate));
    reportStore.setDate(dayjs(homeStore.selectedCalendarDate));
  };

  const getPronunciationDatetime = () => {
    return new Date(homeStore.selectedCalendarDate.getTime() - 1000);
  };

  const handleClickPronunciation = () => {
    pronunciationStore.setSelectedTab("daily");
    history.push("/pronunciation");
  };

  const handleClickMoveReport = () => {
    reportStore.setSelectedTab("daily");
    history.push("/report");
  };

  const { usageStatus, pronunciationGraphList, topicWordList } =
    homeStore.homeData;
  return (
    <Root>
      {tabValue === "board" && (
        <>
          <Header />
          <Container>
            <CalendarBox className="home">
              <Calendar
                locale={languageStore.language}
                activeStartDate={homeStore.isChangeCalendar ? null : homeStore.selectedCalendarDate}
                onActiveStartDateChange={handleChangeActiveDate}
                onChange={handleChangeDay}
                value={homeStore.selectedCalendarDate}
                defaultValue={homeStore.selectedCalendarDate}
                tileContent={tileContent}
                tileClassName={tileClassName}
                tileDisabled={({ date }) => {
                  const now = new Date();
                  const today = new Date(
                    now.getFullYear(),
                    now.getMonth(),
                    now.getDate(),
                    0,
                    0,
                    0
                  );

                  return date > today;
                }}
                formatDay={(locale, date) => moment(date).format("D")}
                calendarType="gregory"
                showNeighboringMonth={false}
              />
            </CalendarBox>
            <TodaySituation>
              <DateBox>
                {DATE_UTIL.getTimeZoneDate(
                  homeStore.selectedCalendarDate,
                  DATE_UTIL.FORMAT.DAY_FORMAT
                )}
              </DateBox>
              <BoxWrap aria-label="홈-오늘 사용현황">
                <Typography>
                  {intl.formatMessage({ id: "usage_status" })} {/* 사용 현황 */}
                </Typography>
                <StatusBox>
                  <Stack justifyContent="center" alignItems="center">
                    <Box className="img" sx={{ background: "#EBFEFF" }}>
                      <Icon icon="emojione:left-speech-bubble" />
                    </Box>
                    <Stack>
                      <StatusText>
                        {intl.formatMessage({ id: "dialog_count" })}
                      </StatusText>
                      <StatusValue>
                        {usageStatus?.dialogCount ?? 0}&nbsp;
                        {intl.formatMessage({ id: "home_count_unit" })}
                      </StatusValue>
                    </Stack>
                  </Stack>
                  <span className="line"></span>
                  <Stack justifyContent="center" alignItems="center">
                    <Box className="img" sx={{ background: "#F9F4FE" }}>
                      <Icon icon="noto:bookmark-tabs" />
                    </Box>
                    <Stack>
                      <StatusText>
                        {intl.formatMessage({ id: "content_usage" })}
                      </StatusText>
                      <StatusValue>
                        {usageStatus?.bookCount ?? 0}&nbsp;
                        {intl.formatMessage({ id: "book_unit" })}
                      </StatusValue>
                    </Stack>
                  </Stack>
                  <span className="line"></span>
                  <Stack justifyContent="center" alignItems="center">
                    <Box className="img" sx={{ background: "#FFF6EB" }}>
                      <Icon icon="emojione:fire" />
                    </Box>
                    <Stack>
                      <StatusText>
                        {intl.formatMessage({ id: "total_usage_time" })}
                      </StatusText>
                      <StatusValue>
                        {COMMON_UTIL.convertSecondsToTime(
                          intl,
                          usageStatus?.totalUsageTime ?? 0
                        )}
                      </StatusValue>
                    </Stack>
                  </Stack>
                </StatusBox>
                <div>
                  <MoreButton onClick={handleClickMoveReport}>
                    {intl.formatMessage({ id: "see_more" })} {/* 자세히 보기 */}
                    <Icon icon="iconamoon:arrow-right-2-thin" fontSize={16}/>
                  </MoreButton>
                </div>
              </BoxWrap>
              <BoxWrap aria-label="홈-발음평가">
                <Typography>
                  {intl.formatMessage({ id: "pronunciation_evaluation" })} {/* 발음 평가 */}
                </Typography>
{/*                 <span style={{ fontSize: "12px" }}>
                  {DATE_UTIL.getTimeZoneDate(
                    getPronunciationDatetime(),
                    DATE_UTIL.FORMAT.AMPM_FORMAT
                  )}
                  &nbsp;
                  {intl.formatMessage({ id: "based_on" })}
                </span> */}
                <BarChartComponent
                  languageStore={languageStore}
                  view={view}
                  pronunciationData={
                    homeStore.homeData ? pronunciationGraphList : []
                  }
                />
                <div>
                  <MoreButton onClick={handleClickPronunciation}>
                    {intl.formatMessage({ id: "see_more" })} {/* 자세히 보기 */}
                    <Icon icon="iconamoon:arrow-right-2-thin" fontSize={16}/>
                  </MoreButton>
                </div>
              </BoxWrap>
              <BoxWrap aria-label="홈-관심키워드">
                <Typography>
                  {intl.formatMessage({ id: "interest_keywords" })} {/* 관심 키워드 */}
                </Typography>
                <Box className="wordcloud" >
                  <WordCloudComponent wordList={topicWordList} />
                </Box>
              </BoxWrap>
            </TodaySituation>
          </Container>
        </>
      )}
      <BottomNavigation
        value={tabValue}
        handleChangeTab={(event, newValue) => setTabValue(newValue)}
      />
    </Root>
  );
}

export default injectIntl(
  inject(
    "authStore",
    "userStore",
    "homeStore",
    "loadingStore",
    "pronunciationStore",
    "reportStore",
    "languageStore"
  )(observer(Home))
);
