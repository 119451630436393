import { Box, Stack, Button, Typography, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";

export const Root = styled(Box)(() => ({
  width: "100%",
  height: "100vh",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  padding: "0 16px",
  boxSizing: "border-box",
}));

export const ContentsBox = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "40% 0 40px",
}));

export const ButtonBox = styled(Box)(() => ({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  marginBottom: "40%",
}));

export const ButtonStyle = styled(Button)(() => ({
  "&.MuiButtonBase-root": {
    position: "relative",
    width: "100%",
    height: 48,
    padding: "0px 16px",
    boxSizing: "border-box",
    borderRadius: 8,
    marginBottom: 10,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& p": {
      fontSize: "0.875rem",
      fontWeight: 400,
      color: "#fff",
      textTransform: "none",
    },
    "&:after": {
      content: "''",
    },
  },
}));

export const NoeButtonStyle = styled(ButtonStyle)(() => ({
  "&.MuiButtonBase-root": {
    border: "1px solid #FF993C",
    background: "#FF993C",
    "&:hover": {
      background: "#FF993C",
    },
    "&:before": {
      width: 15,
      height: 18,
    },
  },
}));

export const GoogleButtonStyle = styled(ButtonStyle)(() => ({
  "&.MuiButtonBase-root": {
    border: "1px solid #c9c9ca",
    background: "#fff",
    "&:hover": {
      background: "#fff",
    },
    "& p": {
      color: "#909195",
    },
    "&:after": {
      width: 20,
      height: 20,
    },
  },
}));

export const AppleButtonStyle = styled(ButtonStyle)(() => ({
  "&.MuiButtonBase-root": {
    border: "1px solid #212121",
    background: "#212121",
    "&:hover": {
      background: "#212121",
    },
    "&:after": {
      width: 14,
      height: 18,
    },
  },
}));

export const KakaoButtonStyle = styled(ButtonStyle)(() => ({
  "&.MuiButtonBase-root": {
    border: "1px solid #FEE500",
    background: "#FEE500",
    "&:hover": {
      background: "#FEE500",
    },
    "& p": {
      color: "#333",
    },
    "&:after": {
      width: 14,
      height: 18,
    },
  },
}));

export const NaverButtonStyle = styled(ButtonStyle)(() => ({
  "&.MuiButtonBase-root": {
    border: "1px solid #03C75A",
    background: "#03C75A",
    marginBottom: 0,
    "&:hover": {
      background: "#03C75A",
    },
    "&:after": {
      width: 14,
      height: 18,
    },
  },
}));

export const RecentlyBox = styled(Box)(() => ({
  position:'absolute',
  right:0,
  top:-5,
  fontSize:'0.75rem',
  color:'#333',
  padding:'4px 12px',
  background: '#FDD751',
  backgroundSize: "container",
  backgroundRepeat: "no-repeat",
  borderRadius:'100px'
}));

export const TitleTextStyle = styled(Typography)(() => ({
  "&.MuiTypography-root": {
    fontSize: "2rem",
    fontWeight: 700,
    color: "#FDD751",
  },
}));

export const TextStyle = styled(Typography)(() => ({
  "&.MuiTypography-root": {
    fontWeight: 400,
    color: "#333",
    marginTop: 16,
    textAlign: "center",
  },
}));

// 로그인 상세
export const SignInRoot = styled(Stack)(() => ({
  width: "100%",
  height: "100vh",
  justifyContent: 'space-between'
}));

// 로그인 상세 정보
export const SignInContentsBox = styled(Box)(() => ({
  width: "100%",
  height: "calc(100% - 170px)",
  padding: "16px 16px 24px",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  "&.modify":{
    height: "calc(100vh - 142px)",
  },
  "&.Terms":{
    justifyContent:'flex-end'
  }
}));

export const InfoBox = styled(Stack)(() => ({
  width: "100%",
  boxSizing: "border-box",
  alignItems:'center',
  gap:40,
}));


export const TextBox = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
}));

export const SignInTitleTextStyle = styled(Typography)(() => ({
  "&.MuiTypography-root": {
    fontSize: "1.25rem",
    fontWeight: 700,
    color: "#212121",
    marginBottom: 8,
  },
}));

export const SignInTextStyle = styled(Typography)(() => ({
  "&.MuiTypography-root": {
    fontWeight: 400,
    color: "#333",
    lineHeight: "19.6px",
    textAlign: "left",
  },
}));


export const ProfileImage = styled('div')({
  width: '120px',
  height: '120px',
  overflow:'hidden',
  padding:'8px',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'center',
  backgroundColor: '#f2f2f2',
  position: 'relative',
});

export const InputField = styled(TextField)({
  marginBottom: '12px',
  width: '100%',
});

export const NoteText = styled(Typography)({
  fontSize: '12px',
  color: '#888',
  textAlign: 'left',
  width: '100%',
  marginBottom: '12px',
  '& b':{
    color:'#FE536B'
  }
});
