import React from "react";
import { injectIntl } from 'react-intl';
import { Avatar, Box, Typography, Stack } from "@mui/material";
import { ReactComponent as ChevronDown } from "../../common/Icons/ChevronDown.svg";
import Detective from "../../common/Images/Detective.png";
import {
  Caption,
  DrawerContents,
  DrawerStyle,
  DrawerTitle,
  IdBox,
  Puller,
  Root,
  SelectButton,
} from "./Styled/HeaderStyled";
import {inject, observer} from "mobx-react";
import dayjs from "dayjs";
import CommonDialog from "../CommonComponent/CommonDialog";

function Header(props) {
  const { authStore, userStore, homeStore, modalStore, pronunciationStore, reportStore, intl } = props;
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };


  const getDeviceDisplayName = (deviceName) => {
    if (deviceName) {
      // return 'POKORO_' + deviceId.substring(0, 4);
      return deviceName;
    } else {
      return intl.formatMessage({ id: "no_connection" });
    }
  }

  const handleClickDevice = (device) => {
    userStore.setNewSelectedDevice(device);
    modalStore.setChangePokoroModalOpen(true);
  }

  const handleChangeDevice = () => {
    userStore.setSelectedDevice(userStore.newSelectedDevice);
    modalStore.setChangePokoroModalOpen(false);
    setOpen(false);
    homeStore.setSelectedCalendarDate(new Date());
    homeStore.getCalendar(authStore.loginUser.id, userStore.selectedDevice.deviceId, (userId, deviceId) => homeStore.getHomeData(userId, deviceId));

    pronunciationStore.setDate(dayjs(homeStore.selectedCalendarDate));
    reportStore.setDate(dayjs(homeStore.selectedCalendarDate));

    homeStore.setIsChangeCalendar(false);
  }

  const handleCancelChangeDevice = () => {
    userStore.setNewSelectedDevice('');
    modalStore.setChangePokoroModalOpen(false);
  }


  return (
    <Root>
      <Box>
        <SelectButton onClick={toggleDrawer(!open)} disableRipple>
          {getDeviceDisplayName(userStore.selectedDevice.deviceName)}
          <ChevronDown style={open ? {transform: 'rotate(180deg)'} : null}/>
        </SelectButton>
        <DrawerStyle
          anchor="top"
          open={open}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
          disableSwipeToOpen={false}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <DrawerTitle>
            <Typography variant="subtitle1">
              {authStore.loginUser.nickname}&nbsp;
              {intl.formatMessage({ id: "device_list" })} {/* (이)가 갖고 있는 포코로 목록 */}
            </Typography>
            <Typography variant="body1" style={{ whiteSpace: "pre-line" }} mt={1}>
              {intl.formatMessage({ id: "select_device_info" })} {/* 포코로를 선택하면 선택한 포코로의 데이터로 변경됩니다. */}
            </Typography>
          </DrawerTitle>
          <DrawerContents>
            <Caption>
              {intl.formatMessage({ id: "total_devices" })}&nbsp;
              {userStore.userDeviceList.length}
              {intl.formatMessage({ id: "total_devices2" })}
            </Caption>
            {userStore.userDeviceList.length === 0 && (
              <Stack alignItems="center" gap={2}>
                <img src={Detective} alt="Detective" width={64} />
                <Typography variant="body2">{intl.formatMessage({ id: "none_connect" })}</Typography>
              </Stack>
            )}
            {userStore.userDeviceList.map((device, index) => (
              <IdBox
                disableRipple
                key={index}
                className={device === userStore.selectedDevice && "selected"}
                onClick={() => handleClickDevice(device)}
              >
                {/* <Avatar src={ConnectionImage}></Avatar> */}
                <Typography variant="body2">{getDeviceDisplayName(device.deviceName)}</Typography>
                <Stack flexDirection='row' gap={1}>
                  <Typography variant="caption">
                    {dayjs(device.lastUsedDatetime).format('YYYY. MM. DD')}
                  </Typography>
                </Stack>
              </IdBox>
            ))}
          </DrawerContents>

          <Puller />
        </DrawerStyle>
      </Box>


      <CommonDialog
          open={modalStore.changePokoroModalOpen}
          title={intl.formatMessage({ id: "change_device_title" })}
          subText={
            <span> {intl.formatMessage({ id: "change_device_info" })}</span>
          }
          onClick1={handleCancelChangeDevice}
          onClick2={handleChangeDevice}
          LeftButtonText={intl.formatMessage({ id: "dialog_NoButton" })}
          RightButtonText={intl.formatMessage({ id: "dialog_YesButton" })}
      />
    </Root>
  );
}

export default injectIntl(inject('authStore', 'userStore', 'homeStore', 'modalStore', 'pronunciationStore', 'reportStore')(observer(Header)));
