import React, { useState } from "react";
import { injectIntl } from "react-intl";
import { useHistory} from "react-router-dom"; // useHistory와 useLocation 추가
import { Typography, Stack, FormControlLabel, Checkbox, Box} from "@mui/material";
import CommonButton from "../CommonComponent/CommonButton";
import CommonDialog from "../CommonComponent/CommonDialog";
import { SubpageTitle } from "../../common/Styled/CommonStyled";
import {TitleBox, ListTitle, ListUl} from "./Styled/LanguageStyled"
import { ReactComponent as CheckIcon } from "../../common/Images/CheckIcon.svg";
import { ReactComponent as UnCheckIcon } from "../../common/Images/UnCheckIcon.svg";
import { inject, observer } from "mobx-react";
import { Icon } from '@iconify/react';

function AccountDelete(props) {
  const { intl, authStore, userStore } = props;
  const history = useHistory();
  const [isChecked, setIsChecked] = useState(false);
  const [open, setOpen] = useState(false);
  const handleBack = () => {
    history.goBack();
  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const isButtonDisabled = () => {
    return !isChecked;
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickClose = () => {
    setOpen(false);
  };

  const handleClickDelete = () => {
    setOpen(false);
    userStore.deleteUser(authStore.loginUser.id, () => {
      authStore.doLogout(() => history.push("/"));
    });
  };


  return (
    <Stack width='100%' height='100vh' justifyContent='space-between'>
      <Box>
        <TitleBox>
          <Icon icon='material-symbols:arrow-back-ios-rounded' onClick={handleBack}/>
          <SubpageTitle sx={{background:'unset'}}>
            <Typography variant="h6" gutterBottom sx={{fontWeight:'bolder'}}>
              {intl.formatMessage({ id: "account_deletion" })} {/* 계정 삭제 */}
            </Typography>
            <Typography variant="body2">
              {intl.formatMessage({ id: "account_deletion_warning" })} {/* 계정삭제 시, 포코로에 연결된 펜의 정보가 모두 삭제됩니다. 삭제후 */}
              <strong>{intl.formatMessage({ id: "deletion_restriction" })} {/* 48시간 동안은 회원가입이 불가능 합니다.*/}</strong>
            </Typography>
          </SubpageTitle>
        </TitleBox>
        <Stack pt={4} gap={1}>
          <ListTitle color={'#333!important'}>
            {intl.formatMessage({ id: "deletion_notice" })}{/* 삭제되는 데이터 */}
          </ListTitle>
          <ListUl>
            {intl.formatMessage({ id: "data_deleted" })}
            {/* 회원정보, 연결한 디바이스 이력 및 모델병, 연결한 디바이스에 저장된 WIFI 동기화 목록, 대화 목록, 발음 평가 대화 데이터, 리포트 데이터, 디바이스 언어설정 */}
            </ListUl>
          <ListUl>
            {intl.formatMessage({ id: "data_storage_notice" })}
            {/* 삭제한 계정에 대한 위의 정보는 보관하지 않고, 즉시 삭제되오니 계정 삭제 시 유의하시기 바랍니다. */}
            </ListUl>
          <ListUl>
            {intl.formatMessage({ id: "irreversible_deletion" })}
            {/* 삭제 시, 해당 계정에 대한 데이터는 복구가 불가능합니다. */}
            </ListUl>
          <ListUl>
            {intl.formatMessage({ id: "re_signup_after_deletion" })}
            {/* 삭제 후,  48시간이 지난 후 재가입이 가능합니다. */}
            </ListUl>
         
        </Stack>
      </Box>
      <Stack gap={1} sx={{padding:'24px 16px'}}>
        <FormControlLabel
          sx={{marginRight:0,}}
          control={
            <Checkbox
              indeterminateIcon={<UnCheckIcon />}
              checkedIcon={<CheckIcon />}
              icon={<UnCheckIcon />}
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
          }
          label={intl.formatMessage({ id: "confirmation_notice" })} /* 위 내용을 확인했으며, 계정 삭제를 진행합니다. */
        />
        <CommonButton
            text={intl.formatMessage({ id: "account_deletion" })} /* 계정 삭제 */
            background={"#FDD751"}
            color={"#333"} d
            disabled={isButtonDisabled()}
            onClick={handleClickOpen}
          />
      </Stack>
      <CommonDialog
        open={open}
        title={intl.formatMessage({ id: "account_deletion_confirmation" })} /* 계정을 삭제하시겠습니까? */
        subText={
          <span>
            <pre>{intl.formatMessage({ id: "irreversible_account_deletion" })}</pre> {/* 계정을 삭제하시면 다시 복구할 수 없습니다. */}
          </span>
        }
        onClick1={handleClickClose}
        onClick2={handleClickDelete}
        LeftButtonText={intl.formatMessage({ id: "dialog_cancle" })} /* 취소 */
        RightButtonText={intl.formatMessage({ id: "dialog_delete" })} /* 삭제 */
      />
    </Stack>    
  );
}

export default injectIntl(inject('authStore', 'userStore')(observer(AccountDelete)));
