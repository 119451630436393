import { Repository } from './Repository';
import axios from "axios";

export default class PronunciationRepository extends Repository {
    constructor(serverUrl) {
        super();

        this.serverUrl = serverUrl;
        this.pronunciationRequestPrefix = '/api/v1/pronunciation';
    }

    getPronunciationStatistics = (userId, deviceId, type, startDatetime, endDatetime, diffTime, language) => {
        return this.getRequestPromise('get', this.serverUrl + this.pronunciationRequestPrefix + `/statistics?userId=${userId}&deviceId=${deviceId}&type=${type}&startDatetime=${startDatetime}&endDatetime=${endDatetime}&diffTime=${diffTime}&language=${language}`);
    };

    getPronunciationList = (userId, deviceId, startDatetime, endDatetime, rowsPerPage, page) => {
        return this.getRequestPromise('get', this.serverUrl + this.pronunciationRequestPrefix + `?userId=${userId}&deviceId=${deviceId}&startDatetime=${startDatetime}&endDatetime=${endDatetime}&rowsPerPage=${rowsPerPage}&page=${page}`);
    };

    getPronunciationAudioUri = (userId, deviceId, pronunciationId) => {
        return this.getRequestPromise('get', this.serverUrl + this.pronunciationRequestPrefix + `/${pronunciationId}/audio-uri?userId=${userId}&deviceId=${deviceId}`);
    };

    getPronunciationAudio = (userId, deviceId, pronunciationId) => {
        return axios.get(this.serverUrl + this.pronunciationRequestPrefix + `/${pronunciationId}/audio?userId=${userId}&deviceId=${deviceId}`, {
            params: {
                userId: userId,
                deviceId: deviceId
            },
            responseType: 'arraybuffer'
        });
    };

    getPronunciationCalendar = (userId, deviceId, startDatetime, diffTime) => {
        return this.getRequestPromise('get', this.serverUrl + this.pronunciationRequestPrefix + `/calendar?userId=${userId}&deviceId=${deviceId}&startDatetime=${startDatetime}&diffTime=${diffTime}`);
    };
}
