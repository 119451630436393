import { default as AuthStore } from "./AuthStore";
import LoadingStore from "./LoadingStore";
import ModalStore from "./ModalStore";
import ConversationStore from "./ConversationStore";
import PronunciationStore from "./PronunciationStore";
import ReportStore from "./ReportStore";
import UserStore from "./UserStore";
import HomeStore from "./HomeStore";
import LanguageStore from "./LanguageStore";

import AuthRepository from "../repositories/AuthRepository";
import UserRepository from "../repositories/UserRepository";
import ConversationRepository from "../repositories/ConversationRepository";
import PronunciationRepository from "../repositories/PronunciationRepository";
import ReportRepository from "../repositories/ReportRepository";

//TODO type 수정
// const type = 'web';
const type = "web";
let serverUrl = '';

if (type === 'dev-app') {
    serverUrl = 'https://pokoro-dev.onthe.live:444';
}

const authRepository = new AuthRepository(serverUrl);
const userRepository = new UserRepository(serverUrl);
const conversationRepository = new ConversationRepository(serverUrl);
const pronunciationRepository = new PronunciationRepository(serverUrl);
const reportRepository = new ReportRepository(serverUrl);

const userStore = new UserStore({userRepository});
const homeStore = new HomeStore({userRepository});
const conversationStore = new ConversationStore({conversationRepository});
const pronunciationStore = new PronunciationStore({pronunciationRepository});
const reportStore = new ReportStore({reportRepository});

export const stores = {
    authStore:  new AuthStore({authRepository, userStore, homeStore, conversationStore, pronunciationStore, reportStore}),
    userStore: userStore,
    homeStore: homeStore,
    conversationStore: conversationStore,
    pronunciationStore: pronunciationStore,
    reportStore: reportStore,
    loadingStore: new LoadingStore(),
    modalStore: new ModalStore(),
    languageStore: new LanguageStore(),
};