import React, { useState, useEffect } from "react";
import { injectIntl } from "react-intl";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import { Typography, Box, Stack, Button } from "@mui/material";
import PoKoRoShyImage from "../../common/Images/PoKoRoShyImage.png";
import PokoroPen from "../../common/Images/PokoroPen.png";
import CommonButton from "../CommonComponent/CommonButton";
import CommonDialog from "../CommonComponent/CommonDialog";
import CommonComfirmDialog from "../CommonComponent/CommonComfirmDialog";
import { COMMON_UTIL } from "../../common/util/common.util";

import {
  ContentsBox,
  LineStyle,
  ListBox,
  PoKoRoDateBox,
  PoKoRoDateText,
  TitleText,
} from "./Styled/SettingStyled";
import { ProfileImage } from "../SignIn/Styled/SignInStyled";
import { Root } from "../../common/Styled/CommonStyled";
import { inject, observer } from "mobx-react";
import dayjs from "dayjs";
import { TitleStyle, LinkButton } from "../../common/Styled/CommonStyled";
import { Icon } from "@iconify/react";

import { usePenStatus } from "../../nativebridge/PenStatus"; // 커스텀 훅을 import
import CommonLoading from "../CommonComponent/CommonLoading";
import {
  connectPokoro,
  stopScan,
  disConnectPokoro,
  customSendToken,
  customSendSetting,
  isConnectedPokoro,
  reqIsConnectedPokoro,
  callBrowser,
} from "../../nativebridge/JsToNative";
import BottomNavigation from "../Home/BottomNavigation";

import {
  NativeToJsBridge,
  NativeToJsEventName,

} from "../../nativebridge/NativeToJs";
import { isSetting } from "../../nativebridge/JsToNative";
import PokoroConnectLoading from "./PokoroConnectLoading";

export const AndroidPackageName = "com.neolab_soundpen";
export const IosAppId =
  "%ED%8F%AC%EC%BD%94%EB%A1%9C-%EC%BB%A4%EB%84%A5%ED%8A%B8/id6477915377";

export const languageMap = {
  US: "English",
  KR: "한국어",
  JP: "日本語",
  CN: "简体中文",
  ES: "Español",
  DE: "Deutsch",
  XA: "ٱلسُّعُوْدِيَّة",
};

function Setting(props) {
  const [tabValue, setTabValue] = useState("setting");
  const { intl, authStore, userStore, languageStore } = props;
  const [open, setOpen] = useState(false);
  const [nickName, setNickName] = useState("POKORO");
  const history = useHistory();
  const location = useLocation();

  // 펜 연결 여부 확인
  const { globalPenStatus, setGlobalPenStatus } = usePenStatus();
  // 로딩바
  const [loading, setLoading] = React.useState(false);

  const [connectfail, setConnectfail] = React.useState(false);
  const [connect, setConnect] = React.useState(false);
  const [disconnect, setDisconnect] = React.useState(false);

  const [reqSetLangAfterConnect, setLangAfterConnect] = React.useState(false);
  const countryCode = COMMON_UTIL.getCountryCodeFromLanguage(
    languageStore.language
  );

  let lang_system = "";

  let reqSetServer = false;
  // useEffect(() => {
  //   console.log(globalPenStatus);
  //   console.log(`useEffect globalPenStatus${reqSetLangAfterConnect}`);
  //   if (reqSetLangAfterConnect) {
  //     setLangAfterConnect(false);
  //     const settingData = {
  //       messageType: "POKORO2_Settings",
  //       payload: {
  //         lang_system: countryCode,
  //         lang_speaking: globalPenStatus.deviceInfo.lang_speaking,
  //         lang_listening_main: globalPenStatus.deviceInfo.lang_listening_main,
  //         lang_listening_sub: globalPenStatus.deviceInfo.lang_listening_sub,
  //       },
  //     };
  //     const lang_speaking = globalPenStatus.deviceInfo.lang_speaking;
  //     console.log(
  //       `customSendSetting globalPenStatus.deviceInfo.lang_system=${countryCode},lang_speaking=${lang_speaking}`
  //     );

  //     customSendSetting(JSON.stringify(settingData));
  //   }
  // }, [reqSetLangAfterConnect, globalPenStatus]);

  useEffect(() => {
    console.log(
      "userStore.selectedDevice.deviceName=${userStore.selectedDevice.deviceName}"
    );
    setNickName(userStore.selectedDevice.deviceName);

    isSetting(true);
    return () => {
      isSetting(false);
    };
  }, []);

  //항상 페이지 상단
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const getDate = () => {
    const now = dayjs(dayjs().format("YYYY-MM-DD"));
    const firstDate = dayjs(
      dayjs(userStore.selectedDevice.firstUsedDatetime).format("YYYY-MM-DD")
    );
    return now.diff(firstDate, "day");
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickClose = () => {
    setOpen(false);
  };

  const handleClickLogout = () => {
    authStore.doLogout(() => history.push("/"));
  };

  // 포코로 연결 여부에 따른 페이지변화(임시)
  // const [isUnConnected, setIsUnConnected] = useState(true); // 초기 연결 상태 설정 (테스트용)
  const handleClickConnect = () => {
    // console.log("=== handleClickConnect" + globalPenStatus);
    console.log(globalPenStatus);
    setLoading(true);
    connectPokoro();
  };
  const closePopup = () => { setLoading(false)};

  const handleClickDisConnect = () => {
    console.log(`=== handleClickDisConnect ${globalPenStatus}`);
    console.log(globalPenStatus);
    disConnectPokoro();
  };
  const onDeviceConnecting = () => {
    // alert("=== onDeviceConnecting");
    console.log("=== onDeviceConnecting");
  };

  const onDeviceConnected = () => {
    // alert("=== onDeviceConnected");
    console.log("=== onDeviceConnected");
    setLoading(false);

    if (lang_system !== countryCode) {
      console.log("=== onDeviceConnected reqSetLangAfterConnect");
      // setLangAfterConnect(true);
      setGlobalPenStatus((prevStatus) => ({
        ...prevStatus,
        deviceInfo: {
          ...prevStatus.deviceInfo, // spread the previous deviceInfo object
          lang_system: countryCode, // update lang_main
        },
      }));


      const settingData = {
        messageType: "POKORO2_Settings",
        payload: {
          lang_system: countryCode,
          lang_speaking: globalPenStatus.deviceInfo.lang_speaking,
          lang_listening_main: globalPenStatus.deviceInfo.lang_listening_main,
          lang_listening_sub: globalPenStatus.deviceInfo.lang_listening_sub,
        },
      };
      const lang_speaking = globalPenStatus.deviceInfo.lang_speaking;
      console.log(
        `customSendSetting globalPenStatus.deviceInfo.lang_system=${countryCode},lang_speaking=${lang_speaking}`
      );

      customSendSetting(JSON.stringify(settingData));
      
    }

    setGlobalPenStatus((prevStatus) => ({
      ...prevStatus, // 현재 상태를 복사
      isConnect: true, // isConnect 속성만 변경
    }));
    setConnect(true);
  };

  const handleConnectDial = () => {
    console.log(`=== handleConnectDial `);
    setConnect(false);
  };

  const onDeviceDisConnected = () => {
    console.log("=== onDeviceDisConnected");
    setLoading(false);
    setGlobalPenStatus((prevStatus) => ({
      ...prevStatus, // 현재 상태를 복사
      isConnect: false, // isConnect 속성만 변경
    }));
    setDisconnect(true);
  };
  const handleDisconnectDial = () => {
    console.log(`=== handleDisconnectDial `);
    setDisconnect(false);
  };

  const onDeviceConnectFail = () => {
    console.log("=== onDeviceConnectFail");
    setLoading(false);
    setGlobalPenStatus((prevStatus) => ({
      ...prevStatus, // 현재 상태를 복사
      isConnect: false, // isConnect 속성만 변경
    }));
    setConnectfail(true);
  };

  const handleConnectFailDial = () => {
    console.log(`=== handleConnectFailDial `);
    setConnectfail(false);
  };

  const onDeviceWifiScanFail = (msg) => {
    // alert(`=== onDeviceWifiScanFail ${msg}`);
    console.log(`=== onDeviceConnectFail ${msg}`);
  };

  const onDeviceWifiScanResult = (jsonStr) => {
    // alert(`=== onDeviceWifiScanResult: ${jsonStr}`);
    console.log(`=== onDeviceWifiScanResult: ${jsonStr}`);
  };

  const onDeviceWifiConnected = () => {
    // alert("=== onDeviceWifiConnected:");
    console.log("=== onDeviceWifiConnected:");
  };

  const onDeviceWifiConnectFail = (msg) => {
    // alert(`=== onDeviceWifiConnectFail ${msg}`);
    console.log(`=== onDeviceWifiConnectFail:${msg}`);
  };

  const onPokoroStatus = (jsonStr) => {
    // alert(`=== onPokoroStatus: ${jsonStr}`);
    console.log(`=== onPokoroStatus: ${jsonStr.jsonStr}`);
    const status = JSON.parse(jsonStr.jsonStr);

    // const countryCode = COMMON_UTIL.getCountryCodeFromLanguage(languageStore.language);

    // if (countryCode !== null && status.payload.deviceInfo.lang_system !== countryCode) {
    //   languageStore.setLanguage(COMMON_UTIL.getLanguageCodeFromCountry(status.payload.deviceInfo.lang_system));
    // }
    const serverList = JSON.parse(localStorage.getItem("_AGENT_SERVER_LIST_"));
    const Server = serverList.find(
      (server) => server.name === status.payload.serverInfo.name
    );
    const ServerUrl = Server ? Server.serverUrl : null;

    console.log(`ServerUrl=${ServerUrl}`);
    setGlobalPenStatus((prevStatus) => ({
      ...prevStatus, // 현재 상태를 복사
      batteryPercentage: status.payload.batteryPercentage,
      storagePercentage: status.payload.storagePercentage,
      ConnectedAP1: status.payload.ConnectedAP1,
      ConnectedAP2: status.payload.ConnectedAP2,
      ConnectedAP3: status.payload.ConnectedAP3,
      deviceInfo: status.payload.deviceInfo,
      serverInfo: {
        ...prevStatus.serverInfo,
        name: status.payload.serverInfo.name,
        url: ServerUrl,
      },
      // deviceInfo: status.payload.deviceInfo,

      name: status.payload.deviceInfo.name,
    }));
    lang_system = status.payload.deviceInfo.lang_system;
    const settingData = {
      messageType: "POKORO2_Settings",
      payload: {
        server_name: status.payload.serverInfo.name,
        server_url: ServerUrl,
      },
    };
    reqSetServer = true;
    customSendSetting(JSON.stringify(settingData));
  };

  const onCustomSendResult = (jsonStr) => {
    const result = JSON.parse(jsonStr.jsonStr);
    console.log(`onCustomSendResult=${result}`);
    if (reqSetServer) {
      reqSetServer = false;
      const authData = {
        messageType: "POKORO2_Auth",
        payload: {
          token: localStorage.getItem("_NDP_ACCESS_TOKEN_"), // 동적으로 주어진 토큰 값
          refreshToken: localStorage.getItem("_NDP_REFRESH_TOKEN_"), // 동적으로 주어진 리프레시 토큰 값
        },
      };
      customSendToken(JSON.stringify(authData));
    }
  };

  useEffect(() => {
    console.log(
      "====isConnectedPokoro: " + isConnectedPokoro()
    );
    if (isConnectedPokoro() === false){
      setGlobalPenStatus((prevStatus) => ({
        ...prevStatus, // 현재 상태를 복사
        isConnect: false, // isConnect 속성만 변경
      }));
    }
    // 신버전 위의 작업을 한번 더 요청함함
    reqIsConnectedPokoro();

    console.log(
      "====_NDP_ACCESS_TOKEN_: " + localStorage.getItem("_NDP_ACCESS_TOKEN_")
    );
    console.log(
      "====_NDP_REFRESH_TOKEN_: " + localStorage.getItem("_NDP_REFRESH_TOKEN_")
    );
    console.log(
      "====_AGENT_SERVER_LIST_: " + localStorage.getItem("_AGENT_SERVER_LIST_")
    );

    //NativeToJsEventOnDeviceConnecting
    NativeToJsBridge.instance.addEventListener(
      NativeToJsEventName.onDeviceConnecting,
      async (event) => {
        onDeviceConnecting();
      }
    );

    //NativeToJsEventOnDeviceConnected
    NativeToJsBridge.instance.addEventListener(
      NativeToJsEventName.onDeviceConnected,
      async (event) => {
        onDeviceConnected();
      }
    );

    //NativeToJsEventOnDeviceDisConnected
    NativeToJsBridge.instance.addEventListener(
      NativeToJsEventName.onDeviceDisConnected,
      async (event) => {
        onDeviceDisConnected();
      }
    );

    //NativeToJsEventOnDeviceConnectFail
    NativeToJsBridge.instance.addEventListener(
      NativeToJsEventName.onDeviceConnectFail,
      async (event) => {
        onDeviceConnectFail();
      }
    );

    //NativeToJsEventOnDeviceWifiScanFail
    NativeToJsBridge.instance.addEventListener(
      NativeToJsEventName.onDeviceWifiScanFail,
      async (event) => {
        const msg = event;
        onDeviceWifiScanFail(msg);
      }
    );

    //NativeToJsEventOnDeviceWifiScanResult
    NativeToJsBridge.instance.addEventListener(
      NativeToJsEventName.onDeviceWifiScanResult,
      async (event) => {
        const jsonStr = event;
        onDeviceWifiScanResult(jsonStr);
      }
    );

    //NativeToJsEventOnDeviceWifiConnected
    NativeToJsBridge.instance.addEventListener(
      NativeToJsEventName.onDeviceWifiConnected,
      async (event) => {
        onDeviceWifiConnected();
      }
    );

    //NativeToJsEventOnDeviceWifiConnectFail
    NativeToJsBridge.instance.addEventListener(
      NativeToJsEventName.onDeviceWifiConnectFail,
      async (event) => {
        const msg = event;
        onDeviceWifiConnectFail(msg);
      }
    );

    //NativeToJsEventOnPokoroStatus
    NativeToJsBridge.instance.addEventListener(
      NativeToJsEventName.onPokoroStatus,
      async (event) => {
        const jsonStr = event;
        onPokoroStatus(jsonStr);
      }
    );

    NativeToJsBridge.instance.addEventListener(
      NativeToJsEventName.onCustomSendResult,
      async (event) => {
        const jsonStr = event;
        onCustomSendResult(jsonStr);
      }
    );

    NativeToJsBridge.instance.addEventListener(
      NativeToJsEventName.onIsPokoroConnect,
      async (event) => {

        console.log("==== onIsPokoroConnect: "+event.isConnect);
        if (event.isConnect === false) {
          setGlobalPenStatus((prevStatus) => ({
            ...prevStatus, // 현재 상태를 복사
            isConnect: false, // isConnect 속성만 변경
          }));
        }
      }
    );

    // 컴포넌트 언마운트 시 이벤트 제거
    return () => {
      NativeToJsBridge.instance.removeEventListener(
        NativeToJsEventName.onDeviceConnecting
      );

      NativeToJsBridge.instance.removeEventListener(
        NativeToJsEventName.onDeviceConnected
      );
      NativeToJsBridge.instance.removeEventListener(
        NativeToJsEventName.onDeviceDisConnected
      );
      NativeToJsBridge.instance.removeEventListener(
        NativeToJsEventName.onDeviceConnectFail
      );
      NativeToJsBridge.instance.removeEventListener(
        NativeToJsEventName.onDeviceWifiScanFail
      );
      NativeToJsBridge.instance.removeEventListener(
        NativeToJsEventName.onDeviceWifiScanResult
      );

      NativeToJsBridge.instance.removeEventListener(
        NativeToJsEventName.onDeviceWifiConnected
      );
      NativeToJsBridge.instance.removeEventListener(
        NativeToJsEventName.onDeviceWifiConnectFail
      );
      NativeToJsBridge.instance.removeEventListener(
        NativeToJsEventName.onPokoroStatus
      );
      NativeToJsBridge.instance.removeEventListener(
        NativeToJsEventName.onCustomSendResult
      );
      NativeToJsBridge.instance.removeEventListener(
        NativeToJsEventName.onIsPokoroConnect
      );
      
      stopScan();

      // buttonElement.removeEventListener('myCustomEvent', handleCustomEvent);
    };
  }, []);

  const handleIconClick = () => {
    const url = 'https://pokoro.notion.site/POKORO-App-1513d084743980acb2a3d6b69acdcf45?pvs=4';

    if (COMMON_UTIL.isWebView()) {
      callBrowser(url);
    } else {
      setTimeout(() => {
        window.open(
            url, '_blank'
        );
      }, 100);
    }
  };

  return (
    <Root>
      {tabValue === "setting" && (
        <>
          {loading && (
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100vw",
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(0,0,0, 0.8)",
                zIndex: 9999,
              }}
              onClick={closePopup}
            >
              <PokoroConnectLoading
                text={intl.formatMessage({
                  id: "bluetooth_search",
                })}
              />
            </div>
          )}

{/*           <Button
           variant="contained"
           onClick={() => {
             setGlobalPenStatus((prevStatus) => ({
               ...prevStatus,
               isConnect: !prevStatus.isConnect,

               serverInfo: {
                 name: "Korea",
                 url: "pokoro-dev.onthe.live:6444",
               },
             }));
             localStorage.setItem(
               "_AGENT_SERVER_LIST_",
               '[{"id":1,"name":"Korea","serverUrl":"pokoro-dev.onthe.live:6444","createdDatetime":"2024-10-01T01:45:26","updatedDatetime":"2024-10-01T01:45:27","enabled":true},{"id":2,"name":"Germany","serverUrl":"pokoroboard.ai","createdDatetime":"2024-10-01T01:46:08","updatedDatetime":"2024-10-01T01:46:09","enabled":true}]'
             );
           }}
          >
           {globalPenStatus.isConnect
             ? "연결끊기 (테스트)"
             : "연결하기 (테스트)"}
          </Button> */}

          <TitleStyle style={{justifyContent:'space-between'}}>
            <Stack flexDirection='row' gap={1} alignItems='center'>
              <Icon icon="emojione:gear" />
              {intl.formatMessage({ id: "setting" })}{/* 설정 */}
            </Stack>
            <Button
              sx={{
                minWidth: "28px",
                width: "28px",
                height: "28px",
                padding: 0,
                backgroundColor: "transparent",
                color: "#1e1e1e",
                "&:active": {
                  backgroundColor: "#FFFCF0",
                },
              }}>
              <Icon icon="mage:message-info-round" onClick={handleIconClick} fontSize={20}/>
            </Button>
          </TitleStyle>
            

          <ContentsBox gap={1} mb={9}>
            <Stack alignItems="center">
              <Box sx={{ position: "relative" }}>
                <ProfileImage>
                  <img src={PokoroPen} alt="Profile" width="70%" />
                </ProfileImage>
              </Box>
              {globalPenStatus.isConnect && (
                <>
                  {/*<Typography variant="h6" textAlign="center" mt={2}>*/}
                  {/*  {nickName}*/}
                  {/*</Typography>*/}
                  <Typography fontSize={14} mt={2}>
                    <Icon icon="material-symbols-light:electric-bolt" />
                    {intl.formatMessage({ id: "battery_level" })}
                    {/* 배터리 잔량 */}:{" "}
                    <span>{globalPenStatus.batteryPercentage}%</span>
                  </Typography>
                </>
              )}
            </Stack>

            {/* 포코로 연결 후에 '/connect?showElements=true'로 갈 수 있을까요? */}
            <Stack gap={0.4} width="100%">
              <CommonButton
                text={
                  globalPenStatus.isConnect
                    ? intl.formatMessage({
                        id: "disconnect_pokoro",
                      }) /* "포코로 설정 완료" */
                    : intl.formatMessage({
                        id: "connect_pokoro",
                      }) /* "포코로 설정하기" */
                }
                background={!globalPenStatus.isConnect ? "#FDD751" : "#FDD751"}
                border={!globalPenStatus.isConnect ? undefined : undefined}
                color={!globalPenStatus.isConnect ? "#333" : "#333"}
                onClick={
                  globalPenStatus.isConnect
                    ? handleClickDisConnect
                    : handleClickConnect
                }
              />
              {globalPenStatus.isConnect && (
                <Typography variant="caption" textAlign="center" color="#909195">
                  {intl.formatMessage({ id: "disconnect_pokoro_txt" })}
                </Typography>
              )}
            </Stack>
            {globalPenStatus.isConnect && (
              <PoKoRoDateBox marginTop={2}>
                <PoKoRoDateText>
                  <pre>
                    {intl.formatMessage({ id: "with_pokoro_days" })}
                    {/* 포코로와 함께한 지 */}
                  </pre>
                  <span>
                    {getDate()}
                    {intl.formatMessage({ id: "days" })}
                  </span>
                  &nbsp;
                  {intl.formatMessage({ id: "is" })}
                  {/* 입니다. */}
                </PoKoRoDateText>
                <img src={PoKoRoShyImage} alt={"pokoro img"} />
              </PoKoRoDateBox>
            )}
            <LineStyle />
            <ListBox>
              <TitleText>
                {intl.formatMessage({ id: "device_settings" })}{" "}
                {/* 디바이스 설정 */}
              </TitleText>
              <LinkButton
                to={{
                  pathname: "/wifiConnect",
                  state: { from: "setting" },
                }}
                disabled={!globalPenStatus.isConnect}
                style={{
                  pointerEvents: !globalPenStatus.isConnect ? "none" : "auto",
                }}
              >
                {intl.formatMessage({ id: "wifi_settings" })} {/* WI-FI 설정 */}
              </LinkButton>
              <LinkButton
                to="/networkRegionSetting"
                disabled={!globalPenStatus.isConnect}
                style={{
                  pointerEvents: !globalPenStatus.isConnect ? "none" : "auto",
                }}
              >
                <Stack flexDirection="row" justifyContent="space-between">
                  <Typography>
                    {intl.formatMessage({ id: "select_region" })}{" "}
                    {/* 네트워크 지역선택 */}
                  </Typography>
                  <Typography
                    className="lang"
                    display={globalPenStatus.isConnect}
                  >
                    {globalPenStatus.serverInfo.name}
                  </Typography>
                </Stack>
              </LinkButton>
              <LinkButton
                to="/speakingLanguageSetting"
                disabled={!globalPenStatus.isConnect}
                style={{
                  pointerEvents: !globalPenStatus.isConnect ? "none" : "auto",
                }}
              >
                <Stack flexDirection="row" justifyContent="space-between">
                  <Typography>
                    {intl.formatMessage({ id: "speaking_language_settings" })}{" "}
                    {/* 말하기 언어설정 */}
                  </Typography>
                  <Typography
                    className="lang"
                    display={globalPenStatus.isConnect}
                  >
                    {languageMap[globalPenStatus.deviceInfo.lang_speaking]}
                  </Typography>
                </Stack>
              </LinkButton>
              <LinkButton
                to="/listeningLanguageSetting"
                disabled={!globalPenStatus.isConnect}
                style={{
                  pointerEvents: !globalPenStatus.isConnect ? "none" : "auto",
                }}
              >
                <Stack flexDirection="row" justifyContent="space-between">
                  <Typography>
                    {intl.formatMessage({ id: "listening_language_settings" })}{" "}
                    {/* 듣기 언어설정 */}
                  </Typography>
                  <Typography
                    className="lang"
                    display={globalPenStatus.isConnect}
                  >
                    {
                      languageMap[
                        globalPenStatus.deviceInfo.lang_listening_main
                      ]
                    }{" "}
                    |{" "}
                    {languageMap[globalPenStatus.deviceInfo.lang_listening_sub]}{" "}
                  </Typography>
                </Stack>
              </LinkButton>
            </ListBox> 
            <ListBox>
              <TitleText>
                {intl.formatMessage({ id: "app_settings" })}
                {/* APP 설정 */}
              </TitleText>
              <LinkButton to="/appLanguageSetting">
                <Stack flexDirection="row" justifyContent="space-between">
                  <Typography>
                    {intl.formatMessage({ id: "language_settings" })}
                    {/* 언어설정 */}
                  </Typography>
                  <Typography className="lang">
                    {languageStore.getDisplayLanguage}
                  </Typography>
                </Stack>
              </LinkButton>
              <LinkButton to="/userInfoSetting">
                {intl.formatMessage({ id: "user_info_change" })}
                {/* 사용자 정보 변경 */}
              </LinkButton>
              <LinkButton to="/termsOfUse">
                {intl.formatMessage({ id: "terms_of_service" })}
                {/* 이용약관 */}
              </LinkButton>
            </ListBox>



            <ListBox sx={{ marginBottom: 0 }}>
              <TitleText>
                {intl.formatMessage({ id: "account_management" })}{" "}
                {/* 계정 관리 */}
              </TitleText>
              <LinkButton to="#" onClick={handleClickOpen}>
                {intl.formatMessage({ id: "logout" })}
                {/* 로그아웃 */}
              </LinkButton>
              <LinkButton to="/accountDelete">
                {intl.formatMessage({ id: "account_deletion" })}{" "}
                {/* 계정삭제 */}
              </LinkButton>
            </ListBox>
          </ContentsBox>
        </>
      )}
      <BottomNavigation
        value={tabValue}
        handleChangeTab={(event, newValue) => setTabValue(newValue)}
      />
      <CommonComfirmDialog
        open={connect}
        title={intl.formatMessage({
          id: "connect_complete_dialog_Title",
        })} /* 포코로 연결 완료 */
        buttonText={intl.formatMessage({ id: "dialog_confirmButton" })}
        onClick={handleConnectDial}
      />
      <CommonComfirmDialog
        open={disconnect}
        title={intl.formatMessage({
          id: "disconnect_dialog_Title",
        })} /* 포코로 연결 해제 */
        /* contents={<pre>{intl.formatMessage({ id: "wifi_complete" })}</pre>} 포코로의 Wi-Fi 연결이 완료되어 앱과 연결을 해제합니다. */
        buttonText={intl.formatMessage({ id: "dialog_confirmButton" })}
        onClick={handleDisconnectDial}
      />
      <CommonComfirmDialog
        open={connectfail}
        title={intl.formatMessage({
          id: "connectfail_dialog_Title",
        })} /* 포코로 연결 실패 */
        buttonText={intl.formatMessage({ id: "dialog_confirmButton" })}
        onClick={handleConnectFailDial}
      />
      <CommonDialog
        open={open}
        title={intl.formatMessage({
          id: "logout_confirmation",
        })} /* 로그아웃 하시겠습니까? */
        subText={
          <pre>
            {intl.formatMessage({ id: "logout_warning" })}
            {/* 로그아웃 하시게 되면,처음부터 다시 연결해야 합니다. */}
          </pre>
        }
        onClick1={handleClickClose}
        onClick2={handleClickLogout}
        LeftButtonText={intl.formatMessage({ id: "dialog_cancle" })}
        RightButtonText={intl.formatMessage({ id: "logout" })} /* 로그아웃 */
      />
    </Root>
  );
}

export default injectIntl(
  inject("authStore", "userStore", "languageStore")(observer(Setting))
);
