import {makeAutoObservable} from "mobx";
import dayjs from "dayjs";
import EnFilter from "bad-words";
import KoFilter from "badwords-ko";

export const State = {
    Authenticated: 'Authenticated',
    NotAuthenticated: 'NotAuthenticated',
    Pending: 'Pending',
    Failed: 'Failed',
    Disabled: 'Disabled',
};

export const LocalStorageTokenKey = '_AUTHENTICATION_TOKEN_';
export const LocalStorageLoginType = '_AUTHENTICATION_LOGIN_TYPE_';
export const LocalStorageRequestLoginType = '_AUTHENTICATION_REQUEST_LOGIN_TYPE_';
export const LocalStorageConnectSkip = '_CONNECT_SKIP_';
export const LocalStorageKeepLogin = '_KEEP_LOGIN_';
export const LocalStorageNdpAccessToken = '_NDP_ACCESS_TOKEN_';
export const LocalStorageNdpRefreshToken = '_NDP_REFRESH_TOKEN_';
export const LocalStorageNdpRefreshTokenExpireDatetime = '_NDP_REFRESH_TOKEN_EXPIRE_DATETIME';
export const LocalStorageAgentServerList = '_AGENT_SERVER_LIST_';

const EmptyLogin = {
    loginType: '',
    code: ''
};

const EmptyUser = {
    id: '',
    nickname: '',
    allowTermsOfAge: false,
    allowTermsOfUse: false,
    allowTermsOfPrivacy: false,
    isEnabled: false,
    isLegacy: false,
    birthDate: '',
    defaultPenName: '',
    loginType: '',
    profileImageUrl: '',
    createdDatetime: '',
    updatedDatetime: '',
};

const logPrefix  = "[AuthStore]"
export default class AuthStore {
    constructor(props) {
        this.authRepository = props.authRepository;
        this.userStore = props.userStore;
        this.homeStore = props.homeStore;
        this.conversationStore = props.conversationStore;
        this.pronunciationStore = props.pronunciationStore;
        this.reportStore = props.reportStore;

        this.enFilter = new EnFilter();
        this.koFilter = new KoFilter();

        this.loginType = '';
        this.loginCode = '';
        this.login = Object.assign({}, EmptyLogin);
        this.loginState = State.NotAuthenticated;
        this.loginToken = '';
        this.loginUser = Object.assign({}, EmptyUser);

        this.termsOfAge = false;
        this.termsOfUse = false;
        this.termsOfPrivacy = false;
        this.nickname = '';
        this.penName = '';
        this.birthDate = '';

        makeAutoObservable(this);
    }


    invalidateLogin = () => {
        this.login = Object.assign({}, EmptyLogin);
        this.loginState = State.NotAuthenticated;
        this.loginToken = '';
        this.loginUser = Object.assign({}, EmptyUser);
    };

    setLoginType = (type) => {
        this.loginType = type;
    }

    setLoginCode = (code) => {
        this.loginCode = code;
    }

    setTermsOfAll = (agree) => {
        this.termsOfAge = agree;
        this.termsOfUse = agree;
        this.termsOfPrivacy = agree;
    }

    setTermsOfAge = (agree) => {
        this.termsOfAge = agree;
    }

    setTermsOfUse = (agree) => {
        this.termsOfUse = agree;
    }

    setTermsOfPrivacy = (agree) => {
        this.termsOfPrivacy = agree;
    }

    setNickname = (nickname) => {
        if (nickname.length <= 16) {
            this.nickname = nickname;
        }
    }

    setPenName = (penName) => {
        this.penName = penName;
    }

    setBirthDate = (birthDate) => {
        this.birthDate = birthDate;
    }

    setLoginState = (state) => {
        this.loginState = state;
    }

    isNotAcceptableNickname = (intl, nickname) => {
        if (nickname !== '') {
            const specialCharactersRegex = /[!@#$%^&*(),.?":{}|<>]/;
            if (specialCharactersRegex.test(nickname)) {
                return intl.formatMessage({ id: "invalid_nickname_special_characters" });
            }

            const incompleteHangulRegex = /[ㄱ-ㅎㅏ-ㅣ]/;
            if (incompleteHangulRegex.test(nickname)) {
                return intl.formatMessage({ id: "invalid_nickname_incorrect" });
            }

            if (nickname.length < 2) {
                return intl.formatMessage({ id: "invalid_nickname_not_enough" });
            }

            if (this.enFilter.isProfane(nickname) || this.koFilter.isProfane(nickname)) {
                return intl.formatMessage({ id: "invalid_nickname_profanity" });
            }
        }

        return '';
    }

    isNotAcceptablePenName = (intl, penName) => {
        if (penName !== '') {
            if (this.enFilter.isProfane(penName) || this.koFilter.isProfane(penName)) {
                return intl.formatMessage({ id: "invalid_pen_name_profanity" });
            }
        }

        return '';
    }

    * doLogin(history, callback) {
        this.loginState = State.Pending;

        try {
            console.log(logPrefix, 'Start doLogin.');

            this.login.loginType = this.loginType;
            this.login.code = this.loginCode;
            const param = this.login;
            const response = yield this.authRepository.login(param);

            const token = response.data.token;
            const user = response.data.user;

            localStorage.setItem(LocalStorageTokenKey, token);
            localStorage.setItem(LocalStorageLoginType, this.loginType);

            console.log('doLogin');
            console.log(this);

            this.loginState = State.Authenticated;
            this.loginToken = token;
            this.loginUser = user;
            this.loginCode = '';

            const accessToken = response.data.ndpAccessToken;
            const refreshToken = response.data.ndpRefreshToken;
            const agentServerList = response.data.agentServerList;
            localStorage.setItem(LocalStorageNdpAccessToken, accessToken);
            localStorage.setItem(LocalStorageNdpRefreshToken, refreshToken);
            localStorage.setItem(LocalStorageNdpRefreshTokenExpireDatetime, dayjs().add(6, 'month').add(-1, 'day'));
            localStorage.setItem(LocalStorageAgentServerList, JSON.stringify(agentServerList));

            this.nickname = this.loginUser.nickname === null ? "" : this.loginUser.nickname;
            this.penName = this.loginUser.defaultPenName === null ? "" : this.loginUser.defaultPenName;
            this.birthDate = this.loginUser.birthDate === null ? "" : this.loginUser.birthDate;

            console.log(logPrefix, 'Finished doLogin.');

            callback && callback();
        } catch (e) {
            console.log(logPrefix, 'Failed doLogin.');
            this.loginToken = '';
            this.loginCode = '';
            this.loginUser = Object.assign({}, EmptyUser);

            if (e.response && e.response.data && e.response.data.errorCode === 'DisabledUser') {
                this.loginState = State.Disabled;
            } else {
                this.loginState = State.Failed;
            }
        }
    }

    * autoLogin(loginType, ndpRefreshToken, callback) {
        this.loginState = State.Pending;

        try {
            console.log(logPrefix, 'Start autoLogin.');

            const param = {loginType: loginType, ndpRefreshToken: ndpRefreshToken}
            const response = yield this.authRepository.autoLogin(param);

            const token = response.data.token;
            const user = response.data.user;

            localStorage.setItem(LocalStorageTokenKey, token);
            localStorage.setItem(LocalStorageLoginType, localStorage.getItem(LocalStorageLoginType));

            console.log('doLogin');
            console.log(this);

            this.loginState = State.Authenticated;
            this.loginToken = token;
            this.loginUser = user;

            const accessToken = response.data.ndpAccessToken;
            const agentServerList = response.data.agentServerList;
            localStorage.setItem(LocalStorageNdpAccessToken, accessToken);
            localStorage.setItem(LocalStorageAgentServerList, JSON.stringify(agentServerList));

            this.nickname = this.loginUser.nickname === null ? "" : this.loginUser.nickname;
            this.penName = this.loginUser.defaultPenName === null ? "" : this.loginUser.defaultPenName;
            this.birthDate = this.loginUser.birthDate === null ? "" : this.loginUser.birthDate;

            console.log(logPrefix, 'Finished autoLogin.');

            callback && callback();
        } catch (e) {
            console.log(logPrefix, 'Failed autoLogin.');
            this.loginState = State.Failed;
            this.loginToken = '';
            this.loginUser = Object.assign({}, EmptyUser);
        }
    }

    * checkLogin() {
        const token = localStorage.getItem(LocalStorageTokenKey);

        if(token) {
            try {
                const response = yield this.authRepository.checkLogin();

                const user = response.data;
                this.loginState = State.Authenticated;
                this.loginUser = user;
            } catch(e) {
                this.loginState = State.NotAuthenticated;
                this.loginToken = '';
                this.loginUser = Object.assign({}, EmptyUser);
            }
        }
    }

    * doLogout(callback) {
        try {
            yield this.authRepository.logout();

            console.log(this);
            this.login = Object.assign({}, EmptyLogin);
            this.loginState = State.NotAuthenticated;
            this.loginToken = '';
            this.loginUser = Object.assign({}, EmptyUser);

            this.userStore.init();
            this.homeStore.init();
            this.conversationStore.init();

            this.pronunciationStore.initDate();
            this.pronunciationStore.init();

            this.reportStore.initDate();
            this.reportStore.init();

            callback && callback();
        } catch(e) {
            this.login = Object.assign({}, EmptyLogin);
            this.loginState = State.NotAuthenticated;
            this.loginToken = '';
            this.loginUser = Object.assign({}, EmptyUser);
        } finally {
            localStorage.removeItem(LocalStorageTokenKey);
            localStorage.removeItem(LocalStorageNdpAccessToken);
            localStorage.removeItem(LocalStorageNdpRefreshToken);
            localStorage.removeItem(LocalStorageNdpRefreshTokenExpireDatetime);
            localStorage.removeItem(LocalStorageAgentServerList);
            localStorage.removeItem(LocalStorageKeepLogin);
        }
    }

    * agreeTerms() {
        try {
            console.log(logPrefix, 'Start agreeTerms.');

            const param = {userId: this.loginUser.id, isAllowTermsOfAge: this.termsOfAge, isAllowTermsOfUse: this.termsOfUse, isAllowTermsOfPrivacy: this.termsOfPrivacy, loginType: this.loginUser.loginType, profileImageUrl: this.loginUser.profileImageUrl}
            yield this.authRepository.agreeTerms(this.loginUser.id, param);

            console.log(logPrefix, 'Finished agreeTerms.');

            this.checkLogin();
        } catch(e) {
            console.log(logPrefix, 'Failed agreeTerms. error: ' + e);
        }
    }

    * updateUserInfo(callback) {
        try {
            console.log(logPrefix, 'Start updateUserInfo.');

            const param = {
                userId: this.loginUser.id,
                nickname: this.nickname,
                birthDate: this.birthDate,
                defaultPenName: this.penName,
                loginType: this.loginUser.loginType,
                profileImageUrl: this.loginUser.profileImageUrl
            }
            yield this.authRepository.updateUserInfo(this.loginUser.id, param);

            this.loginUser.nickname = this.nickname;
            this.loginUser.birthDate = this.birthDate;

            console.log(logPrefix, 'Finished updateUserInfo.');

            callback && callback();
        } catch(e) {
            console.log(logPrefix, 'Failed updateNickname. error: ' + e);
        }
    }

}
