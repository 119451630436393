import { toast } from 'react-toastify';

const defaultToastOptions = {
  position: "bottom-center",
  autoClose: 4000,
  closeButton: false,
  hideProgressBar: true,
  pauseOnHover: true,
  draggable: true,
  style: {
    backgroundColor: 'rgba(0,0,0,0.8)',
    color: '#fff',
    width: '95%',
    margin: '10px auto',
    borderRadius: '4px',
    minHeight: 'auto',
    fontSize: '14px',
    textAlign: 'center',
  },
};

export const useToast = () => {
  const showDeleteToast = (message) => {
    toast(message, defaultToastOptions);
  };

  const showErrorToast = (message) => {
    toast(message, {
      ...defaultToastOptions,
      style: {
        ...defaultToastOptions.style,
        backgroundColor: 'rgba(220,53,69,0.9)', // 에러용 빨간색 배경
      },
    });
  };

  const showSuccessToast = (message) => {
    toast(message, {
      ...defaultToastOptions,
      style: {
        ...defaultToastOptions.style,
        backgroundColor: 'rgba(40,167,69,0.9)', // 성공용 초록색 배경
      },
    });
  };

  return {
    showDeleteToast,
    showErrorToast,
    showSuccessToast,
  };
}; 