import React, { useState, useEffect, useRef } from "react";
import { injectIntl } from "react-intl";
import { Tab, Box, Stack, IconButton } from "@mui/material";
import { inject, observer } from "mobx-react";
import { Icon } from '@iconify/react';
import { CustomTabs } from "../../../common/Styled/CommonStyled";
import { DateTab } from './Styled/PronunciationDataStyled';
import PronunciationList from "./PronunciationList";
import PronunciationGraph from "./PronunciationGraph";
import { CalendarBox } from "../../../common/Styled/CommonStyled";

import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import 'dayjs/locale/ko';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import moment from "moment";
dayjs.extend(isoWeek);
dayjs.extend(advancedFormat);
dayjs.locale('ko');

function PronunciationData(props) {
  const { intl, authStore, userStore, pronunciationStore, languageStore, homeStore, reportStore } = props;
  const { selectedTab } = pronunciationStore;
  const [showCalendar, setShowCalendar] = useState(false);
  const [calendarKey, setCalendarKey] = useState(0);
  const calendarRef = useRef();

  useEffect(() => {
    pronunciationStore.getPronunciationStatistics(authStore.loginUser.id, userStore.selectedDevice.deviceId, languageStore.getLanguageString);
    pronunciationStore.getPronunciationList(authStore.loginUser.id, userStore.selectedDevice.deviceId, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTabChange = (event, newTab) => {
    pronunciationStore.setSelectedTab(newTab);
    pronunciationStore.resetDate(pronunciationStore.calendarDate);
    pronunciationStore.getPronunciationStatistics(authStore.loginUser.id, userStore.selectedDevice.deviceId, languageStore.getLanguageString);
    pronunciationStore.getPronunciationList(authStore.loginUser.id, userStore.selectedDevice.deviceId, false);
  };

   /* 날짜 변경 핸들러 */
  const handleDateChange = (date) => {
    if (date) {
      setShowCalendar(false);
      document.body.style.overflow = "auto";

      pronunciationStore.setDate(dayjs(date));
      pronunciationStore.getPronunciationStatistics(authStore.loginUser.id, userStore.selectedDevice.deviceId, languageStore.getLanguageString);
      pronunciationStore.getPronunciationList(authStore.loginUser.id, userStore.selectedDevice.deviceId, false);

      homeStore.setSelectedCalendarDate(date);
      reportStore.setDate(dayjs(pronunciationStore.calendarDate));
    }
  };

   /* 날짜 표시 포맷 */
  const getDateDisplay = () => {
    if (selectedTab === 'daily') {
      return pronunciationStore.startDatetime.locale(languageStore.language).format('YYYY. MM. DD (ddd)');
    }

    if (selectedTab === 'weekly') {
      return `${pronunciationStore.startDatetime.format('YYYY. MM. DD')} ~ ${pronunciationStore.endDatetime.format('YYYY. MM. DD')}`;
    }

    if (selectedTab === 'monthly') {
      return pronunciationStore.startDatetime.format('YYYY. MM');
    }

    return '';
  };

  /* 날짜 변경 화살표 핸들러 */
  const handleArrowClick = (direction) => {
    pronunciationStore.setDateByDirection(direction);
    pronunciationStore.getPronunciationStatistics(authStore.loginUser.id, userStore.selectedDevice.deviceId, languageStore.getLanguageString);
    pronunciationStore.getPronunciationList(authStore.loginUser.id, userStore.selectedDevice.deviceId, false);

    homeStore.setSelectedCalendarDate(pronunciationStore.calendarDate.toDate());
    reportStore.setDate(dayjs(pronunciationStore.calendarDate));
  };

  /* 오늘 기준 다음날, 다음주, 다음달 넘어가지 못하게 */
  const isNextButtonDisabled = () => {
    const now = dayjs();
    if (selectedTab === 'daily') {
      return pronunciationStore.startDatetime.isSame(now, 'day');
    } else if (selectedTab === 'weekly') {
      return pronunciationStore.startDatetime.isSame(now.subtract(1, 'week').startOf('isoWeek'), 'day');
    } else if (selectedTab === 'monthly') {
      return pronunciationStore.startDatetime.isSame(dayjs(), 'month');
    }
    return false;
  };

  /* 캘린더 외부 클릭 시 닫힘 */
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setShowCalendar(false);
        document.body.style.overflow = "auto";
      }
    };
    if (showCalendar) {
      document.addEventListener("mousedown", handleClickOutside);
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto"; 
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.body.style.overflow = "auto";
    };
  }, [showCalendar]);


  // 데이터 있는 날짜 표시
  const tileContent = ({ date }) => {
    if (pronunciationStore.pronunciationCalendarDateList.includes(moment(date).format("YYYY-MM-DD"))) {
      return (
          <span
              aria-label="데이터 있는 날짜 표시"
              style={{
                width: "4px",
                height: "4px",
                background: "#FF993C",
                borderRadius: "100px",
                position: "absolute",
                bottom: 6
              }}
          />
      );
    }
    return null;
  };

  const handleChangeActiveDate = (event) => {
    pronunciationStore.getPronunciationCalendar(
        authStore.loginUser.id,
        userStore.selectedDevice,
        event.activeStartDate.toISOString()
    ).then(() => {
      setCalendarKey((prevKey) => prevKey + 1);
    });
  };

  const handleOpenCalendar = () => {
    setShowCalendar(true);

    pronunciationStore.getPronunciationCalendar(
        authStore.loginUser.id,
        userStore.selectedDevice,
        pronunciationStore.startDatetime.toISOString()
    ).then(() => {
      setCalendarKey((prevKey) => prevKey + 1);
    });
  }

  return (
    <>
      <Box>
        <CustomTabs
          value={selectedTab}
          onChange={handleTabChange}
          centered
          variant="fullWidth"
        >
          <Tab value="daily" label={intl.formatMessage({ id: "daily" })} />
          <Tab value="weekly" label={intl.formatMessage({ id: "weekly" })} />
          <Tab value="monthly" label={intl.formatMessage({ id: "monthly" })} />
        </CustomTabs>
      </Box>

      <Stack gap={1} mb={9} sx={{ background: '#FBFCFE' }}>
        <DateTab pt={1}>
          <IconButton size="large" onClick={() => handleArrowClick('previous')}>
            <Icon icon={'iconamoon:arrow-left-2-thin'} />
          </IconButton>
          
          <span onClick={handleOpenCalendar} style={{ cursor: 'pointer' }}>
            {getDateDisplay()}
          </span>

          <IconButton size="large" onClick={() => handleArrowClick('next')} disabled={isNextButtonDisabled()}>
            <Icon icon={'iconamoon:arrow-right-2-thin'} />
          </IconButton>

          {showCalendar && (
            <>
              <div style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                zIndex: 105
              }} />

              <CalendarBox ref={calendarRef} className="data" style={{ zIndex: 110 }}>
              <Calendar
                locale={languageStore.language}
                onActiveStartDateChange={handleChangeActiveDate}
                onChange={handleDateChange}
                value={pronunciationStore.calendarDate.toDate()}
                tileContent={tileContent}
                formatDay={(locale, date) => moment(date).format("D")}
                calendarType="gregory"
                showNeighboringMonth={false}
                maxDetail={selectedTab === 'monthly' ? 'year' : 'month'}
                minDetail="decade"
                tileDisabled={({activeStartDate, date, view}) => {
                  const now = new Date();
                  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);

                  if (selectedTab === 'weekly') {
                    return dayjs(date).add(7, 'day').startOf('isoWeek') > dayjs(today).startOf('isoWeek');
                  } else {
                    return date > today;
                  }
                }}
                prev2Label={null}
                next2Label={null}
              />
              </CalendarBox>
            </>
          )}
        </DateTab>
        <PronunciationGraph view={selectedTab} />
        {pronunciationStore.pronunciationList.length > 0 && (
          <PronunciationList view={selectedTab} />
        )}
      </Stack>
    </>
  );
}

export default injectIntl(inject('authStore', 'userStore', 'pronunciationStore', 'languageStore', 'homeStore', 'reportStore')(observer(PronunciationData)));
