import { Box, Stack, InputBase } from "@mui/material";
import { styled } from "@mui/material/styles";

import { CardBox } from "../../../../common/Styled/CommonStyled";
export const Root = styled(Stack)(() => ({
  padding:'24px 16px 40px',
  gap:24,
  "& .MuiTypography-root": {
    display: "-webkit-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    wordBreak: "break-all",
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": "1",
  },
}));

export const StickyTitle = styled(Stack)(() => ({
  flexDirection:'row',
  justifyContent:'space-between',
  alignItems: 'center',
  padding:16,
  background:'#fff',
  position: 'sticky',
  top:0,
  transition:' all 0.3s ease-in-out',
  "svg":{
    fontSize:24
  },
}))

export const WrapBox = styled(Box)(() => ({
  paddingBottom:24
}));
export const CardBoxViewer = styled(CardBox)(() => ({
  margin: 0,
  padding: "24px 16px",
}));
export const ChipBox = styled(Box)(() => ({
  display: "flex",
  gap: 8,
  marginBottom: 8,
  "& .MuiChip-root": {
    height: 19,
    lineHeight: 1,
    "& .MuiChip-label": {
      padding: "4px 16px",
      fontSize: "0.65rem",
      color: "#fff",
    },
  },
}));

export const BoxTitle = styled(Box)(() => ({
  "& .MuiTypography-root": {
    textAlign: "left",
  },
}));

export const ListBox = styled(Box)(() => ({
  marginTop: 16,
  "& ul": {
    padding: "0 0 0 24px",
    margin: 0,
  },
  "& li": {
    fontSize: "0.875rem",
    color: "#909195",
    marginBottom: 3,
  },
}));

export const ChatBox = styled(Stack)(() => ({
  padding: "24px 0",
  gap: 10,
  "& .highlight": {
    fontWeight: 'bold'
  },
  "& .bubble": {
    borderRadius: 24,
    padding: 10,
    fontSize: "0.875rem",
    color: "#333",
  },
}));
export const SpeechBubble = styled(Box)(() => ({
  display: "flex",
  "&.question":{
    justifyContent: "flex-start",
    ".bubble": {
      backgroundColor: "rgb(253 215 78 / 20%)",
      maxWidth:'80%'
    },
  },
  "&.answer":{
    justifyContent: "flex-end",
    ".bubble": {
      backgroundColor: "rgb(133 137 255 / 20%)",
    },
  }
}));

export const InputSearch = styled(InputBase)(() => ({
  position:'relative',
  transition: 'width 0.3s ease',
  "input":{
    position:'absolute',
    backgroundColor:'#fbfcfe',
    borderRadius:100,
    border:'1px solid #f0f1f5',
    minWidth: 180,
    right: 0,
    padding: '4px 8px',
    fontSize: 14,
  },
}));