import React from "react";
import { injectIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { Typography } from "@mui/material";
import {
  Root,
  SubpageTitle,
  LinkButton,
} from "../../common/Styled/CommonStyled";
import { ListBox } from "./Styled/SettingStyled";
import { TitleBox } from "./Styled/LanguageStyled";
import { callBrowser } from "../../nativebridge/JsToNative";
import { Icon } from '@iconify/react';
import {COMMON_UTIL} from "../../common/util/common.util";

function TermsOfUse(props) {
  const { intl, languageStore } = props;
  const history = useHistory();

  const domain = window.location.protocol + '//' + window.location.host;
  const termsUrls = {
    privacyPolicy:  `${domain}/policy/privacy/${languageStore.language === 'ko' ? 'ko' : 'en'}`,
    termsOfService: `${domain}/policy/service/${languageStore.language === 'ko' ? 'ko' : 'en'}`,
    endUserLicenseAgreement: `${domain}/policy/eula/${languageStore.language === 'ko' ? 'ko' : 'en'}`,
  };

  const handleBack = () => {
    history.goBack();
  };

  const handleClick = (url) => {
    if (COMMON_UTIL.isWebView()){
      callBrowser(url);
    }else{
      window.open(url);
      setTimeout(() => {
        // 여기에 클릭 가능한 상태 업데이트 로직을 추가
      }, 100); // 100ms 후에 상태 업데이트
    }
  };

  return (
    <Root>
      <TitleBox>
        <Icon icon='material-symbols:arrow-back-ios-rounded' onClick={handleBack}/>
        <SubpageTitle sx={{ background: "unset" }}>
          <Typography variant="h6" gutterBottom sx={{ fontWeight: "bolder" }}>
            {intl.formatMessage({ id: "terms_of_service" })}
            {/* 이용약관 */}
          </Typography>
          <Typography variant="body2">
            {intl.formatMessage({ id: "terms_description" })}
            {/* 서비스 이용과 관련하여 필요한 사항을 규정합니다. */}
          </Typography>
        </SubpageTitle>
      </TitleBox>

      <ListBox px={2} mt={3}>
        <LinkButton
          to={"/"}
          onClick={(e) => {
            e.preventDefault();
            handleClick(termsUrls.privacyPolicy);
          }}
        >
          {intl.formatMessage({ id: "privacy_policy" })}
          {/* 개인정보 보호정책 */}
        </LinkButton>
        <LinkButton
          to={"/"}
          onClick={(e) => {
            e.preventDefault();
            handleClick(termsUrls.termsOfService);
          }}
        >
          {intl.formatMessage({ id: "service_terms" })}
          {/* 서비스 이용약관 */}
        </LinkButton>
        <LinkButton
          to={"/"}
          onClick={(e) => {
            e.preventDefault();
            handleClick(termsUrls.endUserLicenseAgreement);
          }}
        >
          {intl.formatMessage({ id: "end_user_license_agreement" })}
          {/* 최종사용자 사용권 계약 */}
        </LinkButton>
      </ListBox>
    </Root>
  );
}

export default injectIntl(inject("languageStore")(observer(TermsOfUse)));
