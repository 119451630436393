import React from "react";
import { injectIntl } from "react-intl";
import { BottomNavigationAction } from "@mui/material";
import { Root } from "./Styled/BottomNavigationStyled";
import { Icon } from '@iconify/react';
import { useHistory } from "react-router-dom";
import {inject, observer} from "mobx-react";
import {COMMON_UTIL} from "../../common/util/common.util";  // useHistory import

function BottomNavigation(props) {
  const { intl, homeStore, conversationStore, pronunciationStore, reportStore, value } = props;
  const history = useHistory();

  const handleChangeTab = (event, newValue) => {
    if (newValue !== value) {
      switch (newValue) {
        case "board":
          history.push("/home");
          break;
        case "conversation":
          conversationStore.init();
          history.push("/conversation");
          break;
        case "pronunciation":
          pronunciationStore.init();
          history.push("/pronunciation");
          break;
        case "report":
          reportStore.init();
          history.push("/report");
          break;
        case "setting":
          history.push("/setting");
          break;
        default:
          break;
      }
    }
  };

  return (
    <Root showLabels value={value} onChange={handleChangeTab}>
      <BottomNavigationAction
        label={intl.formatMessage({ id: "home" })}
        value="board"
        disableRipple
        icon={<Icon icon="ion:home-outline" />}
      />
      <BottomNavigationAction
        label={intl.formatMessage({ id: "conversation" })}
        value="conversation"
        icon={<Icon icon="ion:chatbubbles-outline" />}
        disableRipple
      />
      {COMMON_UTIL.isWebView() &&
        <BottomNavigationAction
            label={intl.formatMessage({ id: "pronunciation" })}
            value="pronunciation"
            icon={<Icon icon="ion:megaphone-outline" />}
            disableRipple
        />
      }
      <BottomNavigationAction
        label={intl.formatMessage({ id: "report" })}
        value="report"
        icon={<Icon icon="ion:pie-chart-outline" />}
        disableRipple
      />
      {COMMON_UTIL.isWebView() &&
        <BottomNavigationAction
            label={intl.formatMessage({ id: "setting" })}
            value="setting"
            icon={<Icon icon="ion:settings-outline" />}
            disableRipple
        />
      }
    </Root>
  );
}

export default injectIntl(inject('homeStore', 'conversationStore', 'pronunciationStore', 'reportStore')(observer(BottomNavigation)));