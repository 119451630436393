import {makeAutoObservable} from "mobx";
import {DATE_UTIL} from "../common/util/date.util";
import dayjs from "dayjs";


const logPrefix  = "[PronunciationStore]"
export default class PronunciationStore {

    constructor(props) {
        this.pronunciationRepository = props.pronunciationRepository;

        this.initDate();
        this.init();

        makeAutoObservable(this);
    }

    init = () => {
        this.isPronunciationStatisticsLoading = false;
        this.isPronunciationListLoading = false;

        this.type = 'list';
        this.selectedTab = 'daily';

        this.todayScore = 0;
        this.graphList = [];
        this.goodPhonemeList = [];
        this.badPhonemeList = [];
        this.accuracyScoreAverage = "0";
        this.fluencyScoreAverage = "0";
        this.completenessScoreAverage = "0";
        this.accuracySolution = "";
        this.fluencySolution = "";
        this.speechAttributesSolution = "";
        this.improvementGoalsSolution = "";

        this.pronunciationList = [];
        this.rowsPerPage = 10;
        this.page = 1;

        this.pronunciationCalendarDateList = [];

        this.audio = null;

        this.resetDate(this.calendarDate);
    }

    initDate = () => {
        this.startDatetime = dayjs().startOf('day');
        this.endDatetime = dayjs().endOf('day');
        this.calendarDate = dayjs();
    }

    get getIsLoading() {
        return this.isPronunciationStatisticsLoading || this.isPronunciationListLoading;
    };

    setType = (type) => {
        this.type = type;
    }

    setSelectedTab = (tab) => {
        this.selectedTab = tab;
    }

    setCalendarDate = (date) => {
        this.calendarDate = date;
    }

    resetDate = (date) => {
        const now = dayjs();
        if (this.selectedTab === 'daily') {
            this.startDatetime = date.startOf('day');
            this.endDatetime = date.endOf('day');

            this.calendarDate = date.startOf('day');
        }

        if (this.selectedTab === 'weekly') {
            if (now.startOf('isoWeek').startOf('day').toISOString() === date.startOf('isoWeek').startOf('day').toISOString()) {
                this.startDatetime = date.subtract(1, 'week').startOf('isoWeek').startOf('day');
            } else {
                this.startDatetime = date.startOf('isoWeek').startOf('day');
            }
            this.endDatetime = this.startDatetime.endOf('isoWeek').endOf('day');
        }

        if (this.selectedTab === 'monthly') {
            this.startDatetime = date.startOf('month').startOf('day');
            this.endDatetime = date.endOf('month').endOf('day');

            if (now.startOf('isoWeek').startOf('month').toISOString() !== date.startOf('isoWeek').startOf('month').toISOString()) {
                this.calendarDate = date.startOf('day');
            }
        }
    }

    setDate = (date) => {
        if (this.selectedTab === 'daily') {
            this.startDatetime = date.startOf('day');
            this.endDatetime = date.endOf('day');
        }

        if (this.selectedTab === 'weekly') {
            this.startDatetime = date.startOf('isoWeek').startOf('day');
            this.endDatetime = this.startDatetime.endOf('isoWeek').endOf('day');
        }

        if (this.selectedTab === 'monthly') {
            this.startDatetime = date.startOf('month').startOf('day');
            this.endDatetime = date.endOf('month').endOf('day');
        }

        this.calendarDate = date.startOf('day');
    }

    setDateByDirection = (direction) => {
        if (this.selectedTab === 'daily') {
            const changeDate = direction === 'next' ? 1 : -1;
            this.startDatetime = this.startDatetime.add(changeDate, 'day');
            this.endDatetime = this.endDatetime.add(changeDate, 'day');
            this.calendarDate = this.calendarDate.add(changeDate, 'day');
        }

        if (this.selectedTab === 'weekly') {
            const changeDate = direction === 'next' ? 1 : -1;
            this.startDatetime = this.startDatetime.add(changeDate, 'week');
            this.endDatetime = this.endDatetime.add(changeDate, 'week');
            this.calendarDate = this.calendarDate.add(changeDate, 'week');
        }

        if (this.selectedTab === 'monthly') {
            const changeDate = direction === 'next' ? 1 : -1;
            this.startDatetime = this.startDatetime.add(changeDate, 'month');
            this.endDatetime = this.endDatetime.add(changeDate, 'month');
            this.calendarDate = this.calendarDate.add(changeDate, 'month');
        }
    }

    setTempSearchKeyword = (keyword) => {
        this.tempSearchKeyword = keyword;
    }

    setSearchKeyword = (keyword) => {
        this.searchKeyword = keyword;
    }

    setSearchSortDirection = (direction) => {
        this.page = 1;
        this.searchSortDirection = direction;
    }

    setPage = (page) => {
        this.page = page;
    }

    cancelAudio = () => {
        if (this.audio) {
            this.audio.pause();
            this.audio = null;
        }
    }

    * getPronunciationStatistics(userId, deviceId, language, callback) {
        try {
            console.log(logPrefix, 'Start getPronunciationStatistics.');
            this.isPronunciationStatisticsLoading = true;
            this.todayScore = 0;
            this.graphList = [];
            this.goodPhonemeList = [];
            this.badPhonemeList = [];

            const response = yield this.pronunciationRepository.getPronunciationStatistics(userId, deviceId, this.selectedTab, this.startDatetime.toISOString(), this.endDatetime.toISOString(), DATE_UTIL.getTimeDiffWithUTC(), language);

            this.todayScore = response.data.todayScore;
            this.graphList = response.data.pronunciationGraphList;
            this.goodPhonemeList = response.data.goodPhonemeList;
            this.badPhonemeList = response.data.badPhonemeList;
            this.accuracyScoreAverage = response.data.accuracyScoreAverage;
            this.fluencyScoreAverage = response.data.fluencyScoreAverage;
            this.completenessScoreAverage = response.data.completenessScoreAverage;
            this.accuracySolution = response.data.accuracySolution;
            this.fluencySolution = response.data.fluencySolution;
            this.speechAttributesSolution = response.data.speechAttributesSolution;
            this.improvementGoalsSolution = response.data.improvementGoalsSolution;

            this.isPronunciationStatisticsLoading = false;
            console.log(logPrefix, 'Finished getPronunciationStatistics.');

            callback && callback();
        } catch(e) {
            this.todayScore = 0;
            this.graphList = [];
            this.goodPhonemeList = [];
            this.badPhonemeList = [];
            this.accuracyScoreAverage = "0";
            this.fluencyScoreAverage = "0";
            this.completenessScoreAverage = "0";
            this.accuracySolution = "";
            this.fluencySolution = "";
            this.speechAttributesSolution = "";
            this.improvementGoalsSolution = "";
            console.log(logPrefix, 'Failed getPronunciationStatistics. error: ' + e);
        } finally {
            this.isPronunciationStatisticsLoading = false;
        }
    }

    * getPronunciationList(userId, deviceId, isNextPage, callback) {
        try {
            console.log(logPrefix, 'Start getPronunciationList.');

            if (isNextPage) {
                this.page = this.page + 1;
            } else {
                this.isPronunciationListLoading = true;
                this.pronunciationList = [];
                this.page = 1;
            }

            const response = yield this.pronunciationRepository.getPronunciationList(userId, deviceId, this.startDatetime.toISOString(), this.endDatetime.toISOString(), this.rowsPerPage, this.page);

            if (isNextPage) {
                this.pronunciationList = this.pronunciationList.concat(response.data);
            } else {
                this.pronunciationList = response.data;
            }

            this.isPronunciationListLoading = false;
            console.log(logPrefix, 'Finished getPronunciationList.');

            callback && callback();
        } catch(e) {
            this.pronunciationList = [];
            console.log(logPrefix, 'Failed getPronunciationList. error: ' + e);
        } finally {
            this.isPronunciationListLoading = false;
        }
    }

    * getPronunciationAudioUri(userId, deviceId, pronunciationId, callback) {
        if (this.audio) {
            this.cancelAudio();
        } else {
            try {
                console.log(logPrefix, 'Start getPronunciationAudioUri.');

                const response = yield this.pronunciationRepository.getPronunciationAudioUri(userId, deviceId, pronunciationId);

                console.log(logPrefix, 'Finished getPronunciationAudioUri.');

                if (response.data !== "") {
                    this.audio = new Audio(response.data);
                }

                callback && callback();
            } catch(e) {
                console.log(logPrefix, 'Failed getPronunciationAudioUri. error: ' + e);
            }
        }
    }

    * getPronunciationAudio(userId, deviceId, pronunciationId, callback) {
        if (this.audio) {
            this.cancelAudio();
        } else {
            try {
                console.log(logPrefix, 'Start getPronunciationAudio.');

                const response = yield this.pronunciationRepository.getPronunciationAudio(userId, deviceId, pronunciationId);

                console.log(logPrefix, 'Finished getPronunciationAudio.');

                if (response.data && response.data.byteLength > 0) {
                    const audioBlob = new Blob([response.data], { type: 'audio/mpeg' });
                    const audioUrl = URL.createObjectURL(audioBlob);

                    this.audio = new Audio(audioUrl);
                }

                callback && callback();
            } catch(e) {
                console.log(logPrefix, 'Failed getPronunciationAudio. error: ' + e);
            }
        }
    }

    * getPronunciationCalendar(userId, device, startDatetime) {
        if (device) {
            try {
                console.log(logPrefix, 'Start getPronunciationCalendar.');

                const response = yield this.pronunciationRepository.getPronunciationCalendar(userId, device.deviceId, startDatetime, DATE_UTIL.getTimeDiffWithUTC());

                this.pronunciationCalendarDateList = response.data;

                console.log(logPrefix, 'Finished getPronunciationCalendar.');
            } catch(e) {
                this.pronunciationCalendarDateList = [];
                console.log(logPrefix, 'Failed getPronunciationCalendar. error: ' + e);
            }
        }
    }
}
