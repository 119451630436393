export const maskingType =  {
    id: 'id',
    name: 'name',
}

// 언어 코드 -> 국가 코드 매핑
export const languageToCountryMap = {
    'en': 'US',
    'ko': 'KR',
    'ja': 'JP',
    'zh': 'CN',
};

// 국가 코드 -> 언어 코드 매핑
export const countryToLanguageMap = {
    'US': 'en',
    'KR': 'ko',
    'JP': 'ja',
    'CN': 'zh',
};

class CommonUtil {
    isWebView = () => {
        console.log("isWebView userAgent=" + window.navigator.userAgent);
        // WebView 일 때만 true, PC 또는 모바일 브라우저는 false
        return /inApp/i.test(window.navigator.userAgent);
    };

    convertSecondsToTime = (intl, seconds) => {
        if (seconds < 60) {
            return seconds + ` ${intl.formatMessage({ id: "seconds" })}`;
        }

        let hours = Math.floor(seconds / 3600);
        let minutes = Math.floor((seconds % 3600) / 60);
        // let remainingSeconds = Math.floor(seconds % 60);

        let timeString = '';

        if (hours > 0) {
            timeString += hours + ` ${intl.formatMessage({ id: "hour" })} `;
        }

        if (minutes > 0) {
            timeString += minutes + ` ${intl.formatMessage({ id: "minute" })} `;
        }

        // if (remainingSeconds > 0) {
        //   timeString += remainingSeconds + '초';
        // }

        return timeString.trim();
    }

    getCountryCodeFromLanguage(languageCode) {
        // 매핑된 국가 코드가 있으면 반환, 없으면 null 반환
        return languageToCountryMap[languageCode] || null;
    }
    
    // 국가 코드를 언어 코드로 변환하는 함수
    getLanguageCodeFromCountry(countryCode) {
        return countryToLanguageMap[countryCode] || null;
    }

}

export const COMMON_UTIL = new CommonUtil();