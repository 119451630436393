import React, { useState, useEffect } from "react";
import { injectIntl } from "react-intl";
import { useHistory } from "react-router-dom"; // useHistory와 useLocation 추가
import { Typography, Stack, Box } from "@mui/material";
import { SubpageTitle } from "../../common/Styled/CommonStyled";
import { TitleBox, List } from "./Styled/LanguageStyled";
import { inject, observer } from "mobx-react";
import { usePenStatus } from "../../nativebridge/PenStatus";
import { customSendSetting } from "../../nativebridge/JsToNative";
import CommonLoading from "../CommonComponent/CommonLoading";
import CommonComfirmDialog from "../CommonComponent/CommonComfirmDialog";
import { Icon } from '@iconify/react';
import {
  NativeToJsBridge,
  NativeToJsEventName,
  NativeToJsEventOnDeviceConnected,
} from "../../nativebridge/NativeToJs";

// import languageMap from "./Setting";

function SpeakingLanguageSetting(props) {
  const { intl } = props;
  const languageMap = {
    US: "English",
    KR: "한국어",
    JP: "日本語",
    CN: "简体中文",
    ES: "Español",
    DE: "Deutsch",
    XA: "ٱلسُّعُوْدِيَّة",
  };
  const history = useHistory();
  const { globalPenStatus, setGlobalPenStatus } = usePenStatus();
  const [loading, setLoading] = useState(false);
  const [disconnect, setDisconnect] = React.useState(false);

  const handleBack = () => {
    history.goBack();
  };

  const onDeviceDisConnected = () => {
    setGlobalPenStatus((prevStatus) => ({
      ...prevStatus, // 현재 상태를 복사
      isConnect: false, // isConnect 속성만 변경
    }));
    setDisconnect(true);
    handleBack();
  };


  useEffect(() => {
    console.log(globalPenStatus.deviceInfo.lang_speaking);
    NativeToJsBridge.instance.addEventListener(
      NativeToJsEventName.onDeviceDisConnected,
      async (event) => {
        // const jsonStr = event;
        onDeviceDisConnected();
      }
    );

    NativeToJsBridge.instance.addEventListener(
      NativeToJsEventName.onCustomSendResult,
      async (event) => {
        const jsonStr = event;
        setLoading(false);
      }
    );
    return () => {
      NativeToJsBridge.instance.removeEventListener(
        NativeToJsEventName.onCustomSendResult
      );
      NativeToJsBridge.instance.removeEventListener(
        NativeToJsEventName.onDeviceDisConnected
      );
    };
  }, []);

  const handleLanguageChange = (languageCode) => {
    console.log(`handleLanguageChange languageCode=${languageCode}`);

    if (globalPenStatus.deviceInfo.lang_speaking !== languageCode) {
      setGlobalPenStatus((prevStatus) => ({
        ...prevStatus,
        deviceInfo: {
          ...prevStatus.deviceInfo, // spread the previous deviceInfo object
          lang_speaking: languageCode, // update lang_main
        },
      }));
      const settingData = {
        messageType: "POKORO2_Settings",
        payload: {
          lang_system: globalPenStatus.deviceInfo.lang_system,
          lang_speaking: languageCode,
          lang_listening_main: globalPenStatus.deviceInfo.lang_listening_main,
          lang_listening_sub: globalPenStatus.deviceInfo.lang_listening_sub,
        },
      };
      setLoading(true);
      customSendSetting(JSON.stringify(settingData));
    }
  };

  return (
    <>
      <Stack width="100%">
        <TitleBox>
          <Icon icon='material-symbols:arrow-back-ios-rounded' onClick={handleBack}/>
          <SubpageTitle sx={{ background: "unset" }}>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: "bolder" }}>
              {intl.formatMessage({ id: "speaking_language_settings" })}
              {/* 말하기 언어설정 */}
            </Typography>
            <Typography variant="body2">
              {intl.formatMessage({ id: "language_setting_description" })}
              {/* 포코로에게 말을 걸 때 사용할 언어를 설정합니다. */}
            </Typography>
          </SubpageTitle>
        </TitleBox>
        <Stack pt={4} gap={1}>
          {Object.keys(languageMap).map((languageCode) => (
            <List>
              <Stack
                key={languageCode}
                selected={globalPenStatus.deviceInfo.lang_speaking === languageCode} // 선택된 국가 코드 확인
                onClick={() => handleLanguageChange(languageCode)} // 국가 코드 설정
              >
                <Typography variant="body1">{intl.formatMessage({ id: `${languageCode}` })}</Typography>
                <Typography variant="caption" color='#757575'>{languageMap[languageCode]}{" "}</Typography>
              </Stack>
              {/* 국가 코드에 대응하는 언어 이름 표시 */}
            </List>
          ))}
        </Stack>
      </Stack>
      <CommonComfirmDialog
        open={disconnect}
        title={intl.formatMessage({ id: "disconnect_dialog_Title" })}
        contents={intl.formatMessage({ id: "disconnect_dialog_Title" })}
        buttonText={intl.formatMessage({ id: "dialog_confirmButton" })}
        onClick={handleBack}
      />
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            zIndex: 9999,
          }}
        >
          <CommonLoading text={intl.formatMessage({ id: "setting_sending" })} />
          {/* 세팅 전송 중입니다... */}
        </div>
      )}
    </>
  );
}

export default injectIntl(
  inject("authStore")(observer(SpeakingLanguageSetting))
);
