import {Box, Stack} from "@mui/material";
import { styled } from "@mui/material/styles";
import { TitleStyle } from "../../../../common/Styled/CommonStyled";
import { Typography } from "@material-ui/core";

export const BoxWrap = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: 8,
}));


export const BoxStyle = styled(Stack)(() => ({
  backgroundColor: "#fff",
  padding: "32px 16px",
  gap:24,
  ".otherChild":{
    background:'#FFFCF0',
    padding:'24px 16px'
  }
}));
export const BoxTitle = styled(Box)(() => ({
  "b": {
      color: "#FE536B",
    },
  "& .MuiTypography-subtitle1": {
    color: "#FE536B",
    fontSize: "0.875rem",
    fontWeight: 400,
    textAlign: "left",
    marginBottom: 8,
  },
  "& .MuiTypography-h6": {
    fontSize: "1.125rem",
    color: "#333",
    fontWeight: 700,
    lineHeight: "25.2px",
    textAlign: "left",
  },
  "& .MuiTypography-body1": {
    fontSize: "0.875rem",
    color: "#333",
    fontWeight: 400,
    textAlign: "left",
    "& > span": {
      color: "#FE536B",
    },
  },
  "& .MuiTypography-h7": {
    fontSize:'1rem',
    fontWeight:'bold'
  },
}));

export const SpeechBubble = styled(Box)(({ count }) => ({
  position: 'relative',
  borderRadius: '50%',
  borderWidth: `${Math.min(count / 4 + 8, 18)}px`,
  borderStyle: 'solid',
  borderColor: '#FDD751',
  background: '#FFF',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minWidth: `${Math.min(count * 1.5 + 100, 180)}px`,
  minHeight: `${Math.min(count * 1.5 + 100, 180)}px`,
  left: `${Math.min(count / 4 + 1, 16)}px`,
  zIndex: 2,
  boxSizing:'border-box',
  '&:after': {
    content: '""',
    position: 'absolute',
    bottom: '-4px',
    left: '-4px',
    transform: 'rotate(45deg)',
    borderStyle: 'solid',
    borderWidth: '16px 8px 0',
    borderColor: '#FDD751 transparent transparent transparent',
  },
  '&.other': {
    left: 'unset',
    right: '16px',
    borderColor: '#c9c9ca',
    minWidth: `${Math.min(count * 1.5 + 96, 176)}px`,
    minHeight: `${Math.min(count * 1.5 + 96, 176)}px`,
    color: '#909195',
    zIndex: 1,
    '&:after': {
      borderColor: '#c9c9ca transparent transparent transparent',
      left: 'unset',
      right: '-4px',
      transform: 'rotate(315deg)',
    },
  },
}));

export const ProgressUserBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
}));

export const NickName = styled(Typography)(() => ({
  width: 50,
  fontSize: "0.75rem",
  fontWeight: 400,
  overflow:' hidden',
  textOverflow: 'ellipsis',
  whiteSpace:' nowrap',
  '&.body1':{fontSize: "1rem", textAlign:'center'}
}))

export const ProgressOtherBox = styled(ProgressUserBox)(() => ({
  "& > div": { backgroundColor: "#C9C9CA" },
  "& .MuiTypography-body2": {
    fontSize: "0.75rem",
    color: "#909195",
  },
}));
export const ProgressBox = styled(Box)(() => ({
  height: 19,
  marginRight: 8,
  backgroundColor: "#FDD751",
  borderRadius: 0,
  transition: "all 2s ease-out",
}));
export const BoxTitleStyle = styled(TitleStyle)(() => ({
  fontSize: "1.125rem!important",
  padding: "40px 16px 0 ",
}));