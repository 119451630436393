import React, { useState } from "react";
import { injectIntl } from "react-intl";
import { Typography } from "@mui/material";
import CommonButton from "../CommonComponent/CommonButton";
import { SubpageTitle } from "../../common/Styled/CommonStyled";
import {
  SignInContentsBox,
  SignInRoot,
} from "./Styled/SignInStyled";
import {TitleBox} from '../Setting/Styled/LanguageStyled'
import { inject, observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import CommonDialog from "../CommonComponent/CommonDialog";
import { Icon } from '@iconify/react';
import CheckedComponent from "./CheckedComponent";

function SignInTerms(props) {
  const { intl, authStore } = props;
  const history = useHistory();
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    authStore.agreeTerms();
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickClose = () => {
    setOpen(false);
  };

  const moveLoginPage = () => {
    authStore.doLogout(() => history.push("/"));
  };

  const isButtonDisabled = () => {
    return !(authStore.termsOfAge && authStore.termsOfUse && authStore.termsOfPrivacy);
  };

  return (
    <SignInRoot>
      <TitleBox>
        <Icon icon='material-symbols:arrow-back-ios-rounded' onClick={handleClickOpen}/>
        <SubpageTitle sx={{ background: "unset" }}>
          <Typography variant="h6" gutterBottom sx={{ fontWeight: "bolder" }}>
            {intl.formatMessage({ id: "service_terms" })} {/* 서비스 이용약관 */}
          </Typography>
          <Typography variant="body2">
          <pre>{intl.formatMessage({ id: "signInTerms_text" })}</pre>{/* 해당 서비스를 이용하려면 약관의 동의가 필요해요 */}
          </Typography>
        </SubpageTitle>
      </TitleBox>
       <SignInContentsBox className="Terms">
        <CheckedComponent/>
        <CommonButton
          text={intl.formatMessage({ id: "dialog_confirmButton" })}
          background={"#FDD751"}
          color={"#333"}
          disabled={isButtonDisabled()}
          onClick={handleClick}
        />
       </SignInContentsBox>
      <CommonDialog
        open={open}
        title={intl.formatMessage({ id: "signInfo_DialogTitle" })}
        subText={<pre>{intl.formatMessage({ id: "signIn_DialogSubText" })}</pre>}
        onClick1={moveLoginPage}
        onClick2={handleClickClose}
        LeftButtonText={intl.formatMessage({ id: "dialog_YesButton" })}
        RightButtonText={intl.formatMessage({ id: "dialog_NoButton" })}
      />
    </SignInRoot>
  );
}

export default injectIntl(inject("authStore")(observer(SignInTerms)));
