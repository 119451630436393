import React from "react";
import { injectIntl } from "react-intl";
import {inject, observer} from "mobx-react";
import {
  AllFormControlLabelStyle,
  FormControlLabelBox,
  FormControlLabelBoxIn,
  FormControlLabelStyle,
  IconButtonStyle,
  Root,
} from "./Styled/CheckedComponentStyled";
import { Checkbox, Typography } from "@mui/material";
import { ReactComponent as CheckIcon } from "../../common/Images/CheckIcon.svg";
import { ReactComponent as UnCheckIcon } from "../../common/Images/UnCheckIcon.svg";
import { ReactComponent as ArrowRightIcon } from "../../common/Images/ArrowRightIcon.svg";
import { callBrowser } from "../../nativebridge/JsToNative";
import {COMMON_UTIL} from "../../common/util/common.util";

function CheckedComponent(props) {
  const { intl, authStore, languageStore } = props;

  const handleChangeTermsOfAll = (event) => {
    authStore.setTermsOfAll(event.target.checked);
  };

  const handleChangeTermsOfAge = (event) => {
      authStore.setTermsOfAge(event.target.checked);
  };

  const handleChangeTermsOfUse = (event) => {
      authStore.setTermsOfUse(event.target.checked);
  };

  const handleChangeTermsOfPrivacy = (event) => {
      authStore.setTermsOfPrivacy(event.target.checked);
  };


  const domain = window.location.protocol + '//' + window.location.host;
  const termsUrls = {
    privacyPolicy:  `${domain}/policy/privacy/${languageStore.language === 'ko' ? 'ko' : 'en'}`,
    termsOfService: `${domain}/policy/service/${languageStore.language === 'ko' ? 'ko' : 'en'}`,
    endUserLicenseAgreement: `${domain}/policy/eula/${languageStore.language === 'ko' ? 'ko' : 'en'}`,
  };

  const handleClick = (url) => {
    if (COMMON_UTIL.isWebView()){
      callBrowser(url);
    }else{
      window.open(url);
      setTimeout(() => {
        // 여기에 클릭 가능한 상태 업데이트 로직을 추가
      }, 100); // 100ms 후에 상태 업데이트
    }
  };


  const children = (
    <FormControlLabelBox>
      <FormControlLabelBoxIn>
        <FormControlLabelStyle
          label={
            <Typography>
              {intl.formatMessage({ id: "over_14years" })} <span>({intl.formatMessage({ id: "essential" })})</span>
            </Typography>
          }
          control={
            <Checkbox
              checked={authStore.termsOfAge}
              checkedIcon={<CheckIcon />}
              icon={<UnCheckIcon />}
              onChange={handleChangeTermsOfAge}
              disableRipple
            />
          }
        />
      </FormControlLabelBoxIn>

      <FormControlLabelBoxIn>
        <FormControlLabelStyle
          label={
            <Typography>
              {intl.formatMessage({ id: "agreement_termsOfService" })} <span>({intl.formatMessage({ id: "essential" })})</span>
            </Typography>
          }
          control={
            <Checkbox
              checked={authStore.termsOfUse}
              checkedIcon={<CheckIcon />}
              icon={<UnCheckIcon />}
              onChange={handleChangeTermsOfUse}
              disableRipple
            />
          }
        />
        <IconButtonStyle 
          disableRipple  
          onClick={(e) => {
            e.preventDefault();
            handleClick(termsUrls.termsOfService);
          }}
        >
          <ArrowRightIcon />
        </IconButtonStyle>
      </FormControlLabelBoxIn>

      <FormControlLabelBoxIn>
        <FormControlLabelStyle
          label={
            <Typography>
              {intl.formatMessage({ id: "agreement_info" })} <span>({intl.formatMessage({ id: "essential" })})</span>
            </Typography>
          }
          control={
            <Checkbox
              checked={authStore.termsOfPrivacy}
              checkedIcon={<CheckIcon />}
              icon={<UnCheckIcon />}
              onChange={handleChangeTermsOfPrivacy}
              disableRipple
            />
          }
        />

        <IconButtonStyle 
          disableRipple 
          onClick={(e) => {
            e.preventDefault();
            handleClick(termsUrls.privacyPolicy);
          }}
        >
          <ArrowRightIcon />
        </IconButtonStyle>
      </FormControlLabelBoxIn>
    </FormControlLabelBox>
  );

  return (
    <Root>
      <AllFormControlLabelStyle
        label={intl.formatMessage({ id: "all_agree" })}
        control={
          <Checkbox
            checked={authStore.termsOfAge && authStore.termsOfUse && authStore.termsOfPrivacy}
            onChange={handleChangeTermsOfAll}
            indeterminateIcon={<UnCheckIcon />}
            checkedIcon={<CheckIcon />}
            icon={<UnCheckIcon />}
            disableRipple
          />
        }
      />
      {children}
    </Root>
  );
}

export default injectIntl(inject("authStore", "languageStore")(observer(CheckedComponent)));