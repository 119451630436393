import { Box, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";

export const TitleBox = styled(Stack)(() => ({
  background: '#FBFCFE',
  borderBottom: '1px solid #F0F1F5',
  padding:'24px 16px',
  gap:24,
  "svg":{
    fontSize:24
  },
}))

export const ListTitle = styled(Box)(() => ({
  padding: '4px 16px',
  color: "#909195",
  fontSize: '0.875rem',
  fontWeight: 700,
}));

export const List = styled(Box)(({ selected, disabled }) => ({
  padding: '4px 24px',
  backgroundColor: selected ? '#fffae5' : 'transparent',
  cursor: disabled ? 'not-allowed' : 'pointer', // 비활성화 상태일 때 커서 변경
  opacity: disabled ? 0.5 : 1, // 비활성화 상태에서 흐리게 표시
  pointerEvents: disabled ? 'none' : 'auto', // 비활성화 상태에서 클릭 방지
}));

export const ListUl = styled(Box)(() => ({
  position: 'relative',
  fontSize: '0.875rem',
  color: '#909195',
  marginLeft: 32,
  paddingRight:16,
  '&:after': {
    content: '""',
    position: 'absolute',
    width: 3,
    height: 3,
    top: 8,
    left: -12,
    background: '#909195',
    borderRadius: 100
  }
}));
export const ExImage = styled('div')({
  width: '80px',
  height: '80px',
  overflow: 'hidden',
  position: 'relative',
  "&:after": {
    content: '""',
    position: 'absolute',
    width: 12,
    height: 12,
    bottom: 12,
    right: 30,
    border: '1px solid rgb(254,83,107)',
    borderRadius: 100
  }
});