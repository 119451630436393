import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";


export const ContentsBox = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems:"center",
  padding: 16,
  gap: 16
}));
export const PoKoRoDateBox = styled(Box)(() => ({
  width: "100%",
  padding: 24,
  background: "#FFFCF0",
  boxSizing: "border-box",
  borderRadius: 8,
  display: "flex",
  alignItems: "flex-end",
  justifyContent: "space-between",
  overflow: "hidden",
  position: "relative",
  "& img": {
    position: "absolute",
    right: 40,
    bottom: -60,
  },
}));

export const PoKoRoDateText = styled(Typography)(() => ({
  "&.MuiTypography-root": {
    fontSize: "0.875rem",
    fontWeight: 400,
    lineHeight: "19.6px",
    color: "#333",
    "& span": {
      fontWeight: 700,
      color: "#fe536b",
    },
  },
}));

export const LineStyle = styled(Box)(() => ({
  width: "100%",
  height: 1,
  margin: "24px 0",
  background: "#F0F1F5",
}));

export const ListBox = styled(Box)(() => ({
  marginBottom: 32,
  width: '100%'
}));

export const TitleText = styled(Typography)(() => ({
  "&.MuiTypography-root": {
    fontSize: "1.125rem",
    color: "#333",
    fontWeight: 700,
    lineHeight: "22.4px",
    marginBottom: 8,
  },
}));
