import React from "react";
import EventClassBase from "./event/EventClassBase";

export const getPlatform = (): string => {
  const userAgent = navigator.userAgent || "";
  const platform = navigator.platform || "";

  // iOS 탐지
  if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
    return "iOS";
  }

  // Android 탐지
  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // Web 탐지 (기본적으로 웹으로 처리)
  return "Web";
};

export enum NativeToJsEventName {
  onDeviceConnected = "onDeviceConnected",
  onDeviceConnecting = "onDeviceConnecting",
  onDeviceDisConnected = "onDeviceDisConnected",
  onDeviceConnectFail = "onDeviceConnectFail",
  onDeviceWifiScanFail = "onDeviceWifiScanFail",
  onDeviceWifiScanResult = "onDeviceWifiScanResult",
  onDeviceWifiConnected = "onDeviceWifiConnected",
  onDeviceWifiConnectFail = "onDeviceWifiConnectFail",
  onPokoroStatus = "onPokoroStatus",
  onCustomSendResult = "onCustomSendResult",
  onLoginFail = "onLoginFail",
  onLogin = "onLogin",
  onIsPokoroConnect = "onIsPokoroConnect",
  onDeviceConnectedWifiResult = "onDeviceConnectedWifiResult",
}

export type NativeToJsEventOnDeviceDisconnected = {};

export type NativeToJsEventOnDeviceConnected = {};
export type NativeToJsEventOnDeviceConnecting = {};

export type NativeToJsEventOnDeviceConnectFail = {};
export type NativeToJsEventOnDeviceWifiScanFail = {
  msg: string;
};

export type NativeToJsEventOnDeviceWifiScanResult = {
  jsonStr: string;
};

export type NativeToJsEventOnDeviceWifiConnected = {};
export type NativeToJsEventOnDeviceWifiConnectedFail = {
  msg: string;
};

export type NativeToJsEventOnPokoroStatus = {
  jsonStr: string;
};

export type NativeToJsEventOnCustomSendResult = {
  jsonStr: string;
};
export type NativeToJsEventOnLogin = {
  authcode: string;
};

export type NativeToJsEventOnIsPokoroConnect = {
  isConnect: boolean;
};

export type NativeToJsEventOnDeviceConnectedWifiResult = {
  jsonStr: string;
};


// export type MauiToJsEventOnDeviceNotification = {
//   id: string;
//   bytes: Uint8Array;
//   mac: string;
// };

// export type NativeToJsEvent = NativeToJsEventOnDeviceDisconnected | MauiToJsEventOnDeviceNotification;
export type NativeToJsEvent =
  | NativeToJsEventOnDeviceDisconnected
  | NativeToJsEventOnDeviceConnected
  | NativeToJsEventOnDeviceConnecting
  | NativeToJsEventOnDeviceConnectFail
  | NativeToJsEventOnDeviceWifiScanFail
  | NativeToJsEventOnDeviceWifiScanResult
  | NativeToJsEventOnDeviceWifiConnected
  | NativeToJsEventOnDeviceWifiConnectedFail
  | NativeToJsEventOnPokoroStatus
  | NativeToJsEventOnCustomSendResult
  | NativeToJsEventOnLogin
  | NativeToJsEventOnIsPokoroConnect
  | NativeToJsEventOnDeviceConnectedWifiResult;

export class NativeToJsBridge extends EventClassBase<
  NativeToJsEventName,
  NativeToJsEvent
> {
  // eslint-disable-next-line no-use-before-define
  private static _inst: NativeToJsBridge | null = null;

  static get instance() {
    if (!NativeToJsBridge._inst) {
      NativeToJsBridge._inst = new NativeToJsBridge();
    }
    return NativeToJsBridge._inst;
  }

  private constructor() {
    super();
    this.initGlobalFunction();
  }

  public init() {
    console.log(`[MauiToJsBridge] inited`);
  }

  public addEventListener<T extends NativeToJsEvent>(
    eventName: NativeToJsEventName,
    listener: (event: T) => void | Promise<void>
  ) {
    super.addEventListener(
      eventName,
      listener as (event: NativeToJsEvent) => void | Promise<void>
    );
  }

  private initGlobalFunction() {
    const w = window as any;
    // 포코로 펜 연결 중 알림
    w.onDeviceConnecting = () => {
      NativeToJsBridge.instance.dispatcher?.dispatch(
        NativeToJsEventName.onDeviceConnecting,
        null
      );
    };
    // 포코로 펜 연결 알림
    w.onDeviceConnected = () => {
      NativeToJsBridge.instance.dispatcher?.dispatch(
        NativeToJsEventName.onDeviceConnected,
        null
      );
    };
    // 포코로 펜 연결 실패 알림
    w.onDeviceConnectFail = () => {
      NativeToJsBridge.instance.dispatcher?.dispatch(
        NativeToJsEventName.onDeviceConnectFail,
        null
      );
    };
    // 포코로 펜 연결 종료 알림
    w.onDeviceDisConnected = () => {
      NativeToJsBridge.instance.dispatcher?.dispatch(
        NativeToJsEventName.onDeviceDisConnected,
        null
      );
    };
    // 포코로 펜 wifi 검색 실패 알림
    w.onDeviceWifiScanFail = (msg: string) => {
      NativeToJsBridge.instance.dispatcher?.dispatch(
        NativeToJsEventName.onDeviceWifiScanFail,
        { msg }
      );
    };
    // 포코로 펜 wifi 검색 결과 알림
    w.onDeviceWifiScanResult = (jsonStr: string) => {
      NativeToJsBridge.instance.dispatcher?.dispatch(
        NativeToJsEventName.onDeviceWifiScanResult,
        { jsonStr }
      );
    };

    // 포코로 펜 wifi 연결 알림(연결 직후 블루투스는 연결 종료됨)
    w.onDeviceWifiConnected = () => {
      NativeToJsBridge.instance.dispatcher?.dispatch(
        NativeToJsEventName.onDeviceWifiConnected,
        null
      );
    };

    // 포코로 펜 wifi 연결 실패 알림(msg-reason-는 있을수도 있고 없을수도 있음)
    w.onDeviceWifiConnectFail = (msg: string) => {
      NativeToJsBridge.instance.dispatcher?.dispatch(
        NativeToJsEventName.onDeviceWifiConnectFail,
        { msg }
      );
    };
    // 포코로 펜 펌웨어 세팅 값 알림
    // 펌웨어 세팅 값 알림을 먼저 전달하고 이후 펜 연결 알림을 전달함)
    // 즉 펌웨어 세팅값을 가져오지 못하면 연결 실패 취급
    w.onPokoroStatus = (jsonStr: string) => {
      NativeToJsBridge.instance.dispatcher?.dispatch(
        NativeToJsEventName.onPokoroStatus,
        { jsonStr }
      );
    };

    // 포코로 펜 Custom data 전달 결과 알림
    w.onCustomSendResult = (jsonStr: string) => {
      NativeToJsBridge.instance.dispatcher?.dispatch(
        NativeToJsEventName.onCustomSendResult,
        { jsonStr }
      );
    };

    // 로그인 cancel 또는 fail
    w.onLoginFail = () => {
      NativeToJsBridge.instance.dispatcher?.dispatch(
        NativeToJsEventName.onLoginFail,
        null
      );
    };

    // 로그인 성공 authcode 전달
    w.onLogin = (authcode: string) => {
      NativeToJsBridge.instance.dispatcher?.dispatch(
        NativeToJsEventName.onLogin,
        { authcode }
      );
    };
    w.onIsPokoroConnect = (isConnect: boolean) => {
      NativeToJsBridge.instance.dispatcher?.dispatch(
        NativeToJsEventName.onIsPokoroConnect,
        { isConnect }
      );
    };

    // 포코로 펜 wifi 검색 결과 알림
    w.onDeviceConnectedWifiResult = (jsonStr: string) => {
      NativeToJsBridge.instance.dispatcher?.dispatch(
        NativeToJsEventName.onDeviceConnectedWifiResult,
        { jsonStr }
      );
    };

    //     w.onDeviceDiscoveringUpdate = (jsonStr: string) => {
    //       const devices = JSON.parse(jsonStr) as IDiscoveredDevices[];
    //       this.dispatcher.dispatch(NativeToJsEventName.onDeviceDiscoveringUpdate, { devices });
    //     };

    //     w.onReportDeviceScanningFinished = (jsonStr: string) => {
    //       const devices = JSON.parse(jsonStr) as IDiscoveredDevices[];
    //       this.dispatcher.dispatch(NativeToJsEventName.onReportDeviceScanningFinished, { devices });
    //     };

    //     w.onDeviceNotification = (id: string, val: string | Uint8Array, mac: string) => {
    //       try {
    //         let bytes: Uint8Array;
    //         if (typeof val === 'string') {
    //           const binaryString = window.atob(val);
    //           const len = binaryString.length;
    //           bytes = new Uint8Array(len);
    //           for (let i = 0; i < len; i++) {
    //             bytes[i] = binaryString.charCodeAt(i);
    //           }
    //         } else {
    //           bytes = val;
    //         }
    //         MauiPokoroBTManager.instance.onPacketReceived(id, bytes, mac);
    //         return true;
    //       } catch (e) {
    //         console.log(e);
    //         return false;
    //       }
    //     };

    //     w.onDeviceDisconnected = (id: string) => {
    //       this.dispatcher.dispatch(NativeToJsEventName.onDeviceDisconnected, { id });
    //     };
  }
}
