import React, { useState, useEffect } from "react";
import { injectIntl } from "react-intl";
import { useHistory, useLocation } from "react-router-dom"; // useHistory와 useLocation 추가
import Tooltip from '@mui/material/Tooltip';
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'; // 이 import 추가
import {
  Box,
  Typography,
  Stack,
  IconButton,
  Button,
  InputAdornment,

} from "@mui/material";
import {
  ActionBox,
  DialogBox,
  LeftButtonStyle,
  RightButtonStyle,
  TextStyle,
  TitleText,
} from "../CommonComponent/Styled/CommonDialogStyled";
import {
  WifiListTitle,
  HeaderTitle,
  WifiList,
  WifiItem,
  AddNewWifi,
  ModalContent,
} from "./Styled/WifiConnectStyled";
import { InputField } from "../SignIn/Styled/SignInStyled";
import { LinkButton } from "../../common/Styled/CommonStyled";
import { SubpageTitle } from "../../common/Styled/CommonStyled";
import { inject, observer } from "mobx-react";
import { Icon } from "@iconify/react";
import { ReactComponent as Wifi3Lock } from "../../common/Icons/wifi-3-lock.svg";
import { ReactComponent as Wifi2Lock } from "../../common/Icons/wifi-2-lock.svg";
import { ReactComponent as Wifi1Lock } from "../../common/Icons/wifi-1-lock.svg";
import { LineStyle } from "../Setting/Styled/SettingStyled";
import { usePenStatus } from "../../nativebridge/PenStatus"; // 커스텀 훅을 import
import CommonLoading from "../CommonComponent/CommonLoading";
import CommonComfirmDialog from "../CommonComponent/CommonComfirmDialog";
import CommonDialog from "../CommonComponent/CommonDialog";
import { TitleBox } from "../Setting/Styled/LanguageStyled";
import {
  startWifiScan,
  wifiInputPassword,
  OnDeviceConnectedWifiResult,
  reqPokoroConnectedWifiList,
  reqRemoveWifi
} from "../../nativebridge/JsToNative";
import {
  NativeToJsBridge,
  NativeToJsEventName,
  getPlatform,
} from "../../nativebridge/NativeToJs";
import { useToast } from '../../components/useToast';

// 컴포넌트 외부에 전역 변수 추가
let currentWifiNetwork = null;
let originalWifiNetworksCache = [];  // 원본 Wi-Fi 네트워크 캐시 추가

function WifiConnect(props) {
  const { intl } = props;
  const history = useHistory();
  const location = useLocation(); // 현재 위치 정보 가져오기
  const [selectedNetwork, setSelectedNetwork] = useState(-1);
  // const [selectedConnectedNetwork, setSelectedConnectedNetwork] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [loadingText, setLoadingText] = useState(
    intl.formatMessage({ id: "searching_to_wifi" })
  );
  const [open, setOpen] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(false);
  const [selectedDeleteIndex, setSelectedDeleteIndex] = useState(-1);
  const [selectedWifiInfo, setSelectedWifiInfo] = useState(null);

  const { showDeleteToast, showErrorToast, showSuccessToast } = useToast();

  const handleTooltipToggle = () => {
    setOpenTooltip((prevOpen) => !prevOpen);
  };

  // 펜 연결 여부 확인
  const { globalPenStatus, setGlobalPenStatus } = usePenStatus();
  const [wifiNetworks, setWifiNetworks] = useState([]);
  const [wifiConnectedList, setWifiConnectedList] = useState([]);
  const [loading, setLoading] = React.useState(false);
  const [disconnect, setDisconnect] = React.useState(false);
  const [wifiConnected, setWifiConnected] = React.useState(false);
  const [wifiConnectFail, setWifiConnectFail] = React.useState(false);
  const [wifiFailReasonTitle, setWifiFailReasonTitle] = React.useState("");
  const [wifiFailReasonMsg, setWifiFailReasonMsg] = React.useState("");
  const platform = getPlatform();
  const mapWifiConnectedList = new Map();
  const [connectedWifiInfo, setConnectedWifiInfo] = useState(null);

  console.log('Current wifiNetworks:', wifiNetworks);

  /* 와이파이 삭제 토스트 메시지 */
  const DeleteWifi = () => {
    if (selectedDeleteIndex !== -1) {
      const deletedWifiName = wifiConnectedList[selectedDeleteIndex].name;
      console.log(`handleConnectedNetworkDelete reqRemoveWifi === ${deletedWifiName}`);
      reqRemoveWifi(deletedWifiName);

      // 연결된 와이파이 목록에서 삭제
      setWifiConnectedList((prevList) => prevList.filter((_, i) => i !== selectedDeleteIndex));

      // 원본 스캔 데이터에서 삭제된 와이파이와 동일한 SSID를 찾아서 wifiNetworks에 추가
      const matchedNetwork = originalWifiNetworksCache.find(network => network.name === deletedWifiName);
      if (matchedNetwork) {
        setWifiNetworks(prevNetworks => [...prevNetworks, matchedNetwork]);
      }
    }
    handleClickClose();
    setSelectedDeleteIndex(-1);
  };

  /* 와이파이 연결 불가 토스트 메시지 */
  const showWifiWarningToast = () => {
    showErrorToast(
      <span style={{ whiteSpace: "pre-line" }}>
        {intl.formatMessage({ id: "Toast_warning_wifi" })}
      </span>
    );
  };
  

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    if (error) setError("");
  };

  const onDeviceDisConnected = () => {
    setLoading(false);
    setGlobalPenStatus((prevStatus) => ({
      ...prevStatus, // 현재 상태를 복사
      isConnect: false, // isConnect 속성만 변경
    }));
    setDisconnect(true);
    handleGoSetting();
    // handleBack();
  };

  const handleNetworkSelect = (index) => {
    if (wifiConnectedList.length > 2) {
      showErrorToast(
        <span style={{ whiteSpace: "pre-line" }}>
          {intl.formatMessage({ id: "Toast_warning_wifi" })}
        </span>
      );
    } else if (
      wifiNetworks.length > 0 &&
      index >= 0 &&
      index < wifiNetworks.length
    ) {
      currentWifiNetwork = wifiNetworks[index];  // 전역 변수에 저장
      setSelectedNetwork(index);

      //open wifi
      if (currentWifiNetwork.security < 3 && platform !== "Web") {
        setLoadingText(
          intl.formatMessage({ id: "connecting_to_wifi" })
        );
        setLoading(true);
        wifiInputPassword(currentWifiNetwork.name, "");
      } else {
        setOpenModal(true);
      }
    }
  };

  const handleConnect = () => {
    console.log(`handleConnect === ${password}`);
    if (platform === "Web") {
      if (password === "password") {
        alert(intl.formatMessage({ id: "wifi_connected" }));
        setOpenModal(false);
        setPassword("");
      } else {
        setError(
          intl.formatMessage({ id: "incorrect_password" })
        );
      }
    } else {
      if (password.length > 0 && currentWifiNetwork) {
        setOpenModal(false);
        setLoadingText(
          intl.formatMessage({ id: "connecting_to_wifi" })
        );
        setLoading(true);
        wifiInputPassword(currentWifiNetwork.name, password);
      } else {
        setError(
          intl.formatMessage({ id: "enter_password" })
        );
      }
    }
  };

  const handleConnectedNetworkDelete = (index) => {
    console.log(`handleConnectedNetworkDelete === ${index}`);
    if (
      wifiConnectedList.length > 0 &&
      index >= 0 &&
      index < wifiConnectedList.length
    ) {
      console.log(`handleConnectedNetworkDelete reqRemoveWifi === ${wifiConnectedList[index].name}`);
      reqRemoveWifi(wifiConnectedList[index].name);

      setWifiConnectedList((prevList) => prevList.filter((_, i) => i !== index));
    }
  };

  const handleClickOpen = (index) => {
    setSelectedDeleteIndex(index);
    setOpen(true);
  };
  const handleClickClose = () => {
    setOpen(false);
    setSelectedDeleteIndex(-1);
  };

  const handleClose = () => {
    setOpenModal(false);
    setPassword("");
    currentWifiNetwork = null;  // 전역 변수 초기화
  };

  const handleBack = () => {
    console.log(`wifi connect handleBack= ${location.state?.from}`);
    if (location.state?.from === "setting") {
      history.goBack(); // Setting 페이지로 돌아가기
    } else {
      history.push("/connect"); // Connect 페이지로 돌아가기
    }
  };
  const handleGoSetting = () => {
    history.push("/setting"); // setting 페이지로 돌아가기
  };


  const handleWifiFail = () => {
    setWifiConnectFail(false);
  };

  // 새로운 Wi-Fi 네트워크를 추가하는 함수
  const addWifiNetwork = (name, signalStrength) => {
    // setWifiNetworks((prevNetworks) => [
    //   ...prevNetworks, // 기존 네트워크 목록 유지
    //   { name, signalStrength }, // 새로운 네트워크 추가
    // ]);
  };

  // 여러 개의 Wi-Fi 네트워크를 한꺼번에 추가하는 함수
  const addMultipleWifiNetworks = (newNetworks) => {
    setWifiNetworks((prevNetworks) => [
      ...prevNetworks, // 기존 네트워크 목록 유지
      ...newNetworks, // 새로운 네트워크 목록을 추가
    ]);
  };

  const onDeviceWifiScanFail = () => {
    setLoading(false);
    console.log("WifiConnect ===onDeviceWifiScanFail");
  };

  const onDeviceWifiScanResult = (jsonStr) => {
    console.log(
      `WifiConnect ===onDeviceWifiScanResult ${JSON.stringify(jsonStr)}`
    );
    const wifis = jsonStr.jsonStr;
    const mappedWifiNetworks = wifis.map((network) => {
      const signalStrength = convertSignalStrength(network.rssi);

      if ((globalPenStatus.ConnectedAP1.ssid !== "" && globalPenStatus.ConnectedAP1.ssid === network.wifiName)) {
        mapWifiConnectedList.set(network.wifiName, {
          name: network.wifiName,
          signalStrength: signalStrength,
          icon: getWifiIcon(network.security > 4 ? 5 : signalStrength, network.security >= 3 ? true : false),
          security: network.security,
          password: globalPenStatus.ConnectedAP1.password,
        });
      }
      if ((globalPenStatus.ConnectedAP2.ssid !== "" && globalPenStatus.ConnectedAP2.ssid === network.wifiName)) {
        mapWifiConnectedList.set(network.wifiName, {
          name: network.wifiName,
          signalStrength: signalStrength,
          icon: getWifiIcon(network.security > 4 ? 5 : signalStrength, network.security >= 3 ? true : false),
          security: network.security,
          password: globalPenStatus.ConnectedAP2.password,
        });
      }
      if ((globalPenStatus.ConnectedAP3.ssid !== "" && globalPenStatus.ConnectedAP3.ssid === network.wifiName)) {
        mapWifiConnectedList.set(network.wifiName, {
          name: network.wifiName,
          signalStrength: signalStrength,
          icon: getWifiIcon(network.security > 4 ? 5 : signalStrength, network.security >= 3 ? true : false),
          security: network.security,
          password: globalPenStatus.ConnectedAP3.password,
        });
      }

      return {
        name: network.wifiName,
        signalStrength: signalStrength,
        icon: getWifiIcon(network.security > 4 ? 5 : signalStrength, network.security >= 3 ? true : false),
        security: network.security,
      };
    });

    // 전역 변수에 원본 데이터 저장
    originalWifiNetworksCache = mappedWifiNetworks;
    
    // React 상태도 업데이트
    setWifiNetworks(mappedWifiNetworks);

    // 연결된 Wi-Fi 목록 요청
    reqPokoroConnectedWifiList();
  };

  const onDeviceWifiConnected = () => {
    setLoading(false);
    setPassword("");

    console.log("WifiConnect ===onDeviceWifiConnected currentWifiNetwork:", currentWifiNetwork);

    if (currentWifiNetwork) {
      console.log("WifiConnect ===onDeviceWifiConnected name=" + currentWifiNetwork.name);

      // 연결된 Wi-Fi 목록에 추가
      setWifiConnectedList(prev => [...prev, currentWifiNetwork]);

      // 사용 가능한 Wi-Fi 목록에서 제거 (이름으로 비교)
      setWifiNetworks(prev => prev.filter(network => network.name !== currentWifiNetwork.name));

      // 선택된 네트워크 정보 초기화
      setSelectedNetwork(-1);
      currentWifiNetwork = null;  // 전역 변수 초기화
    } else {
      console.log("WifiConnect ===onDeviceWifiConnected: Wi-Fi connection successful but no network info");
    }

    showSuccessToast(intl.formatMessage({ id: "Toast_success_wifi" }));
  };
  const onDeviceWifiConnectFail = (msg) => {
    setLoading(false);
    setPassword("");
    console.log(`WifiConnect ===onDeviceWifiConnectFail : ${msg.msg}`);

    if (msg.msg === '0') {
      setWifiFailReasonTitle(
        intl.formatMessage({ id: "wifi_fail_title" })
      );
      setWifiFailReasonMsg(
        intl.formatMessage({ id: "wifi_fail_msg" })
      );
      showErrorToast(intl.formatMessage({ id: "wifi_fail_msg" }));
    } else {
      setWifiFailReasonTitle(
        intl.formatMessage({ id: "wifi_fail_title" })
      );
      setWifiFailReasonMsg(
        intl.formatMessage({ id: "wifi_fail_msg2" })
      );
      showErrorToast(intl.formatMessage({ id: "wifi_fail_msg2" }));
    }




    console.log(`WifiConnect ===onDeviceWifiConnectFail Title: ${wifiFailReasonTitle} , msg:${wifiFailReasonMsg}`);
    setWifiConnectFail(true);
  };


  const onDeviceConnectedWifiResult = (jsonStr) => {
    console.log(
      `WifiConnect ===onDeviceConnectedWifiResult ${JSON.stringify(jsonStr)}`
    );

    const wifis = jsonStr.jsonStr;

    // 1. 연결된 WiFi의 SSID 목록을 Set으로 생성
    const connectedSsids = new Set(wifis.map(wifi => wifi.ssid).filter(ssid => ssid.length > 0));

    // 2. wifiNetworks에서 연결된 WiFi 제거
    setWifiNetworks(prevNetworks =>
      prevNetworks.filter(network => !connectedSsids.has(network.name))
    );

    // 3. 연결된 WiFi 목록 생성 (위에 코드와 다르게 cache에 값이 없으면 useState를 사용)
    const mapWifiConnectedList = wifis.map((network) => {
      console.log('Finding match for ssid:', network.ssid);
      console.log('originalWifiNetworksCache:', originalWifiNetworksCache);
      
      // 캐시와 상태 모두 확인하여 Wi-Fi 정보 찾기
      const matchedWifi = originalWifiNetworksCache.find((wifi) => network.ssid === wifi.name);
      
      
      console.log('Matched wifi:', matchedWifi);

      // 기본값 설정
      let signalStrength = 0;
      let security = 0;

      if (matchedWifi) {
        signalStrength = matchedWifi.signalStrength;
        security = matchedWifi.security;
      }

      if (network.ssid.length > 0) {
        return {
          name: network.ssid,
          signalStrength: signalStrength,
          security: security,
          icon: getWifiIcon(security > 4 ? 5 : signalStrength, security >= 3 ? true : false),
        };
      }
      return null;
    }).filter(Boolean);

    console.log('Final wifiConnectedList:', mapWifiConnectedList);
    setWifiConnectedList(mapWifiConnectedList);
    setLoading(false);
  };

  const getWifiIcon = (signalStrength, isLock) => {
    switch (signalStrength) {
      case 1:
        if (isLock)
          return <Icon icon="fluent:wifi-lock-24-regular" />; //4칸
        else
          return <Icon icon="fluent:wifi-1-24-regular" />; //4칸
      case 2:
        if (isLock)
          return <Wifi3Lock />; //3칸 잠금
        else
          return <Icon icon="fluent:wifi-2-24-regular" />; //3칸
      case 3:
        if (isLock)
          return <Wifi2Lock />; //2칸 잠금
        else
          return <Icon icon="fluent:wifi-3-24-regular" />; //2칸
      case 4:
        if (isLock)
          return <Wifi1Lock />; //1칸 잠금
        else
          return <Icon icon="fluent:wifi-4-24-regular" />; //1칸
      case 5:
        return <Icon icon="fluent:wifi-off-24-regular" />; //연결불가
      case 6:
        return <Icon icon="fluent:wifi-warning-24-regular" />; //연결에러
      // case 7:
      //   return <Icon icon="fluent:wifi-lock-24-regular" />; //4칸 잠금
      // case 8:
      //   return <Wifi3Lock />; //3칸 잠금
      // case 9:
      //   return <Wifi2Lock />; //2칸 잠금
      // case 10:
      //   return <Wifi1Lock />; //1칸 잠금
      default:
        return <Icon icon="fluent:wifi-5-24-regular" />; //0칸
    }
  };

  // RSSI 값을 신호 강도로 변환하는 함수
  const convertSignalStrength = (rssi) => {
    if (rssi >= -50) return 1; // 신호 강도 4칸
    if (rssi >= -60) return 2; // 신호 강도 3칸
    if (rssi >= -70) return 3; // 신호 강도 2칸
    if (rssi >= -80) return 4; // 신호 강도 1칸
    return 0; // 0칸
  };

  const handleRefresh = () => {
    setWifiNetworks([]);
    setWifiConnectedList([]);

    setLoadingText(intl.formatMessage({ id: "searching_to_wifi" })); /* Wifi를 검색 중입니다.. */
    setLoading(true);
    startWifiScan();
  };

  useEffect(() => {
    if (platform === "Web") {
      const dummy = [
        { name: "Wifi01_5G", signalStrength: 1, security: 3 },
        { name: "Wifi02_5G", signalStrength: 2, security: 3 },
        { name: "Wifi03_5G", signalStrength: 3, security: 0 },
        { name: "Wifi04_5G", signalStrength: 4, security: 3 },
        { name: "Wifi05_5G", signalStrength: 5, security: 3 },
        { name: "Wifi06_5G", signalStrength: 6, security: 3 },
        { name: "Wifi07_5G", signalStrength: "default", security: 3 },
      ];
      setWifiNetworks(dummy);
    } else {
      setLoadingText(intl.formatMessage({ id: "searching_to_wifi" })); /* Wifi를 검색 중입니다.. */
      setLoading(true);
      // reqPokoroConnectedWifiList();
      startWifiScan();

    }

    //NativeToJsEventOnDeviceDisConnected
    NativeToJsBridge.instance.addEventListener(
      NativeToJsEventName.onDeviceDisConnected,
      async (event) => {
        onDeviceDisConnected();
      }
    );

    //NativeToJsEventOnDeviceWifiScanFail
    NativeToJsBridge.instance.addEventListener(
      NativeToJsEventName.onDeviceWifiScanFail,
      async (event) => {
        onDeviceWifiScanFail();
      }
    );
    //NativeToJsEventOnDeviceWifiScanResult
    NativeToJsBridge.instance.addEventListener(
      NativeToJsEventName.onDeviceWifiScanResult,
      async (event) => {
        const jsonStr = event;
        onDeviceWifiScanResult(jsonStr);
      }
    );
    //NativeToJsEventOnDeviceWifiConnected
    NativeToJsBridge.instance.addEventListener(
      NativeToJsEventName.onDeviceWifiConnected,
      async (event) => {
        onDeviceWifiConnected();
      }
    );
    //NativeToJsEventOnDeviceWifiConnectFail
    NativeToJsBridge.instance.addEventListener(
      NativeToJsEventName.onDeviceWifiConnectFail,
      async (event) => {
        const jsonStr = event;
        onDeviceWifiConnectFail(jsonStr);
      }
    );

    //NativeToJsEventOnDeviceConnectedWifiResult
    NativeToJsBridge.instance.addEventListener(
      NativeToJsEventName.onDeviceConnectedWifiResult,
      async (event) => {
        const jsonStr = event;
        onDeviceConnectedWifiResult(jsonStr);
      }
    );



    // 컴포넌트 언마운트 시 이벤트 제거
    return () => {
      NativeToJsBridge.instance.removeEventListener(
        NativeToJsEventName.onDeviceConnected
      );
      NativeToJsBridge.instance.removeEventListener(
        NativeToJsEventName.onDeviceWifiScanFail
      );
      NativeToJsBridge.instance.removeEventListener(
        NativeToJsEventName.onDeviceWifiScanResult
      );
      NativeToJsBridge.instance.removeEventListener(
        NativeToJsEventName.onDeviceWifiConnected
      );
      NativeToJsBridge.instance.removeEventListener(
        NativeToJsEventName.onDeviceWifiConnectFail
      );
      NativeToJsBridge.instance.removeEventListener(
        NativeToJsEventName.onDeviceConnectedWifiResult
      );
    };
  }, []);

  return (
    <Stack width="100%">
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            zIndex: 9999, // 충분히 큰 z-index로 다른 요소들 위에 올리기
          }}
        >
          <CommonLoading text={loadingText} />
        </div>
      )}
      <CommonComfirmDialog
        open={disconnect}
        title={intl.formatMessage({ id: "disconnect_dialog_Title" })}
        contents={intl.formatMessage({ id: "disconnect_dialog_Title" })}
        buttonText={intl.formatMessage({ id: "dialog_confirmButton" })}
        onClick={handleGoSetting}
      />
      <CommonComfirmDialog
        open={wifiConnected}
        title={intl.formatMessage({ id: "wifi_connected" })}
        contents={intl.formatMessage({ id: "wifi_connected" })}
        buttonText={intl.formatMessage({ id: "dialog_confirmButton" })}
        onClick={handleGoSetting}
      />
      <CommonComfirmDialog
        open={wifiConnectFail}
        title={wifiFailReasonTitle}
        contents={wifiFailReasonMsg}
        buttonText={intl.formatMessage({ id: "dialog_confirmButton" })}
        onClick={handleWifiFail}
      />
      <TitleBox>
        <Icon icon='material-symbols:arrow-back-ios-rounded' onClick={handleBack} />
        <SubpageTitle sx={{ background: "unset" }}>
          <Typography variant="h6" gutterBottom sx={{ fontWeight: "bolder" }}>
            {intl.formatMessage({ id: "wifi_connection" })} {/* Wi-Fi 연결 */}
          </Typography>
          <Typography variant="body2">
            {intl.formatMessage({ id: "select_wifi" })}{" "}
            {/* 포코로에 연결할 Wi-Fi를 선택해주세요. */}
          </Typography>
        </SubpageTitle>
      </TitleBox>

      {/* 나의 wi-fi */}
      <Stack pt={4} gap={1}>
        <WifiListTitle px={2}>
          <HeaderTitle gap={1}>
            {intl.formatMessage({ id: "connected_wifi_list" })} {/* 나의 Wi-Fi */}
            <Tooltip
              title={intl.formatMessage({ id: "wifi_tooltip" })}
              open={openTooltip}
              onClose={() => setOpenTooltip(false)}
              onOpen={() => setOpenTooltip(true)}
              leaveDelay={3000}
              arrow
            >
              <Icon
                icon="material-symbols-light:info-outline"
                style={{ cursor: 'pointer' }}
                onClick={handleTooltipToggle}
              />
            </Tooltip>
          </HeaderTitle>
        </WifiListTitle>

        <WifiList spacing={1}>
          {wifiConnectedList.map((network, index) => (
            <WifiItem
              key={index}
            >
              <Typography>{network.name}</Typography>
              <Stack flexDirection='row' gap={1}>
                {getWifiIcon(network.signalStrength)}
                <Icon
                  icon="material-symbols-light:delete-outline"
                  onClick={() => handleClickOpen(index)}
                  style={{ cursor: 'pointer' }}
                />
              </Stack>
            </WifiItem>
          ))}
        </WifiList>
      </Stack>
      <LineStyle />

      <Stack pt={4} gap={1}>
        <WifiListTitle px={2}>
          <HeaderTitle>
            {intl.formatMessage({ id: "available_wifi" })}{" "} {/* 연결 가능한 Wi-Fi */}
          </HeaderTitle>
          <Button
            variant="outlined"
            size="small"
            startIcon={<Icon icon="ic:round-refresh" />}
            sx={{
              borderRadius: "20px",
              borderColor: "#909195",
              color: "#909195",
              textTransform: "none",
              fontSize: "0.75rem",
              padding: "2px 8px",
            }}
            onClick={() => handleRefresh()}
          >
            {intl.formatMessage({ id: "refresh" })} {/* 새로고침 */}
          </Button>
        </WifiListTitle>

        <WifiList spacing={1}>
          {wifiNetworks.map((network, index) => (
            <WifiItem
              key={index}
              onClick={network.security > 4 ? null : () => handleNetworkSelect(index)}
              selected={selectedNetwork === index}
              style={{
                pointerEvents: network.security > 4 ? 'none' : 'auto',
                opacity: network.security > 4 ? 0.5 : 1,
              }}
            >
              <Typography>{network.name}</Typography>
              {getWifiIcon(network.security > 4 ? 5 : network.signalStrength, network.security >= 3 ? true : false)}
            </WifiItem>
          ))}
        </WifiList>
        <Box onClick={(e) => {
          if (wifiConnectedList.length > 2) {
            e.preventDefault();
            showErrorToast(intl.formatMessage({ id: "Toast_warning_wifi" }));
          }
        }}>
          <LinkButton to="/newWifiConnect">
            <AddNewWifi px={2}>
              {intl.formatMessage({ id: "add_new_wifi" })}
            </AddNewWifi>
          </LinkButton>
        </Box>
      </Stack>

      <DialogBox open={openModal} onClose={handleClose}>
        <TitleText>
          {intl.formatMessage({ id: "enter_wifi_password" })}{" "}
          {/* Wi-Fi 비밀번호 입력 */}
        </TitleText>
        <TextStyle color="#909195">
          {intl.formatMessage({ id: "wifi_password_prompt" })}{" "}
          {/* 연결하려는 Wi-Fi의 비밀번호를 입력해주세요. */}
        </TextStyle>
        <ModalContent>
          <Stack flexDirection="row" color="#909195" fontSize={14}>
            {intl.formatMessage({ id: "name" })}{" "}
            <Typography
              variant="body1"
              style={{ color: "#333", marginLeft: "8px" }}
            >
              {wifiNetworks.length > 0 &&
                selectedNetwork >= 0 &&
                selectedNetwork < wifiNetworks.length
                ? wifiNetworks[selectedNetwork].name
                : "No Network Available"}
            </Typography>
          </Stack>
          <InputField
            type={showPassword ? "text" : "password"}
            onChange={handlePasswordChange}
            label={intl.formatMessage({ id: "password" })}
            error={Boolean(error)}
            helperText={error}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleTogglePasswordVisibility}>
                    <Icon
                      icon={
                        showPassword ? "ri:eye-line" : "ri:eye-off-line"
                      }
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{ marginTop: "8px" }}
          />
        </ModalContent>
        <ActionBox sx={{ marginTop: "16px" }}>
          <LeftButtonStyle disableRipple onClick={handleClose}>
            {intl.formatMessage({ id: "dialog_NoButton" })}
          </LeftButtonStyle>
          <RightButtonStyle disableRipple onClick={handleConnect}>
            {intl.formatMessage({ id: "dialog_YesButton" })}
          </RightButtonStyle>
        </ActionBox>
      </DialogBox>
      <CommonDialog
        open={open}
        title={intl.formatMessage({
          id: "Delete_wifi",
        })} /* Wi-Fi를 삭제하시겠습니까? */
        subText={
          <pre>
            {intl.formatMessage({ id: "Delete_wifi_txt" })}
            {/* 삭제하신 Wi-Fi는 재연결해야 사용 가능하빈다. */}
          </pre>
        }
        onClick1={handleClickClose}
        onClick2={DeleteWifi}
        LeftButtonText={intl.formatMessage({ id: "dialog_cancle" })}
        RightButtonText={intl.formatMessage({ id: "dialog_delete" })} /* 삭제 */
      />
      <ToastContainer
        position="bottom-center"
        limit={1}
        newestOnTop={false}
        closeButton={false}
        hideProgressBar
        rtl={false}
        pauseOnFocusLoss
        draggable
        theme="colored"
      />
    </Stack>
  );
}

export default injectIntl(inject("authStore")(observer(WifiConnect)));
