import * as store from "./stores/AuthStore";
import React from "react";
import {injectIntl} from "react-intl";
import { Reset } from "styled-reset";
import { withStyles } from "@material-ui/core/styles";
import axios from "axios";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { inject, observer } from "mobx-react";

import Home from "./views/Home/Home";
import CommonDialog from "./views/CommonComponent/CommonDialog";
import DeviceDataDialogComponent from "./views/Home/DeviceDataDialogComponent";
import Conversation from "./views/Home/Conversation/Conversation";
import Pronunciation from "./views/Home/Pronunciation/Pronunciation";
import Report from "./views/Home/Report/Report";
import Setting from "./views/Setting/Setting";
import UserInfoSetting from "./views/Setting/UserInfoSetting";
import Connect from "./views/Connect/Connect";
import WifiConnect from "./views/Connect/WifiConnect";
import AppLanguageSetting from "./views/Setting/AppLanguageSetting";
import SpeakingLanguageSetting from "./views/Setting/SpeakingLanguageSetting";
import ListeningLanguageSetting from "./views/Setting/ListeningLanguageSetting";
import TermsOfUse from "./views/Setting/TermsOfUse";
import NetworkRegionSetting from "./views/Setting/NetworkRegionSetting";
import AccountDelete from "./views/Setting/AccountDelete";
import NewWifiConnect from "./views/Connect/NewWifiConnect";
import KoEulaPolicy from "../src/policy/eula/ko";
import EnEulaPolicy from "../src/policy/eula/en";
import KoPrivacyPolicy from "../src/policy/privacy/ko";
import EnPrivacyPolicy from "../src/policy/privacy/en";
import KoServicePolicy from "../src/policy/service/ko";
import EnServicePolicy from "../src/policy/service/en";

import { GlobalProvider } from "./nativebridge/PenStatus";
import SignIn from "./views/SignIn/SignIn";
import SignInInfo from "./views/SignIn/SignInInfo";
import SignInInTerms from "./views/SignIn/SignInTerms";
import TermsRevised from "./views/SignIn/TermsRevised";

const style = () => ({
  root: {
    display: "flex",
  },
});

class App extends React.Component {
  componentDidMount() {
    const { loadingStore } = this.props;
    const axiosRequestInterceptors = (config) => {
      const token = localStorage.getItem(store.LocalStorageTokenKey);

      if (token) {
        config.headers["X-Auth-Token"] = token;
      }

      return config;
    };

    const axiosRequestErrorHandler = (error) => {
      return Promise.reject(error);
    };

    const axiosResponseInterceptor = (response) => {
      if (response.status === 403) {
        this.props.authStore.invalidateLogin();
        loadingStore.setIsLoading(false);
      }

      return response;
    };

    const axiosResponseErrorHandler = (error) => {
      if (error.response && error.response.status === 403) {
        this.props.authStore.invalidateLogin();
        loadingStore.setIsLoading(false);
      }

      return Promise.reject(error);
    };

    console.log("========== RGate App componentDidMount ==========");
    axios.interceptors.request.use(
      axiosRequestInterceptors,
      axiosRequestErrorHandler
    );
    axios.interceptors.response.use(
      axiosResponseInterceptor,
      axiosResponseErrorHandler
    );

    this.props.authStore.checkLogin();
  }

  setMobileOpen(mobileOpen) {
    this.setState({ mobileOpen: mobileOpen });
  }

  render() {
    const { intl, classes } = this.props;
    const { loginState, loginUser } = this.props.authStore;
    const { loadingStore } = this.props;

    const agreeTerms =
        loginUser.allowTermsOfAge === true &&
        loginUser.allowTermsOfUse === true &&
        loginUser.allowTermsOfPrivacy === true;

    let finalPath = "/";
    if (loginState === store.State.Authenticated) {
      finalPath = localStorage.getItem(store.LocalStorageConnectSkip) ? "/home" : "/connect";

      if (!agreeTerms) {
        if (loginUser.legacy === true) {
          finalPath = "/signin/termsRevised";
        } else {
          finalPath = "/signin/terms";
        }
      } else if(loginUser.nickname === null || loginUser.nickname === "") {
        finalPath = "/signin/nickname";
      }
    }

    return (
        <div className={classes.root}>
          <GlobalProvider>
             <Router>
              <Reset />
              <Route>
                <Switch>
                  <Route exact path="/policy/privacy/ko" component={KoPrivacyPolicy} />
                  <Route exact path="/policy/eula/ko" component={KoEulaPolicy} />
                  <Route exact path="/policy/service/ko" component={KoServicePolicy} />
                  <Route exact path="/policy/privacy/en" component={EnPrivacyPolicy} />
                  <Route exact path="/policy/eula/en" component={EnEulaPolicy} />
                  <Route exact path="/policy/service/en" component={EnServicePolicy} />
                  {loginState !== store.State.Authenticated && (
                    <>
                      <Route exact path="/" component={SignIn} />
                      <Route exact path="/signin" component={SignIn} />
                      <Redirect to="/" />
                    </>
                  )}
                  {loginState === store.State.Authenticated && (
                    <>
                      <Redirect to={finalPath} />
                      <Route exact path="/signin/terms" component={SignInInTerms}/>
                      <Route exact path="/signin/termsRevised" component={TermsRevised}/>
                      <Route exact path="/signin/nickname" component={SignInInfo}/>
                      <Route exact path="/" component={Home} />
                      <Route exact path="/home" component={Home} />
                      <Route exact path="/wifiConnect" component={WifiConnect} />
                      <Route exact path="/connect" component={Connect} />
                      <Route path="/conversation" component={Conversation} />
                      <Route path="/pronunciation" component={Pronunciation} />
                      <Route path="/report" component={Report} />
                      <Route path="/termsOfUse" component={TermsOfUse} />
                      <Route path="/setting" component={Setting} />
                      <Route path="/appLanguageSetting" component={AppLanguageSetting}/>
                      <Route path="/userInfoSetting" component={UserInfoSetting}/>
                      <Route path="/speakingLanguageSetting" component={SpeakingLanguageSetting}/>
                      <Route path="/listeningLanguageSetting" component={ListeningLanguageSetting}/>
                      <Route path="/networkRegionSetting" component={NetworkRegionSetting}/>
                      <Route path="/newWifiConnect" component={NewWifiConnect}/>
                      <Route path="/accountDelete" component={AccountDelete} />
                    </>
                  )}
                </Switch>
              </Route>
              {/* <Switch>
               <Route exact path="/" component={Home} />
               <Route exact path="/home" component={Home} />
               <Route exact path="/wifiConnect" component={WifiConnect} />
               <Route exact path="/connect" component={Connect} />
               <Route path="/conversation" component={Conversation} />
               <Route path="/pronunciation" component={Pronunciation} />
               <Route path="/report" component={Report} />
               <Route path="/setting" component={Setting} />
               <Route path="/appLanguageSetting" component={AppLanguageSetting} />
               <Route path="/userInfoSetting" component={UserInfoSetting} />
               <Route path="/speakingLanguageSetting" component={SpeakingLanguageSetting} />
               <Route path="/listeningLanguageSetting" component={ListeningLanguageSetting} />
               <Route path="/termsOfUse" component={TermsOfUse} />
               <Route path="/accountDelete" component={AccountDelete} />
               <Route path="/networkRegionSetting" component={NetworkRegionSetting}/>
               <Route path="/newWifiConnect" component={NewWifiConnect}/>
              </Switch> */}
            </Router>
            <CommonDialog
              open={loadingStore.isLoading}
              title={intl.formatMessage({ id: "data_device" })}
              subText={intl.formatMessage({ id: "conversation_data" })}
              subTextColor={"#909195"}
              childrenBox={<DeviceDataDialogComponent />}
              action={false}
            />
          </GlobalProvider>
        </div>
    );
  }
}

export default withStyles(style)(
  injectIntl(inject("authStore", "loadingStore")(observer(App)))
);
