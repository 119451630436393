import { height, styled, width } from '@mui/system';
import { Box, Stack, Typography } from '@mui/material';

export const WifiListTitle = styled(Stack)({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const HeaderTitle = styled(Typography)({
  display: 'flex',
  alignItems: 'center',
  fontSize: '1rem',
  fontWeight: 'bold',
});

export const WifiList = styled(Stack)({});

export const WifiItem = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '8px 24px',
  cursor: 'pointer',
  '& svg': {
    fontSize:'1.25rem',
    width: '1.25rem',
    height:'1.25rem'
  }
});

export const AddNewWifi = styled(Typography)({
  fontSize: '0.875rem',
  fontWeight: 'bold',
  color: '#333',
  cursor: 'pointer',
});

export const ModalContent = styled(Stack)({
  alignItems: 'flex-start',
  marginTop: '24px'
});