import React, { useEffect } from "react";
import { useLocation } from "react-router";
import { injectIntl } from "react-intl";
import { useHistory } from "react-router-dom"; // useHistory와 useLocation 추가
import { Typography, Stack, Box } from "@mui/material";
import { SubpageTitle } from "../../common/Styled/CommonStyled";
import { TitleBox, ListTitle, List, ExImage } from "./Styled/LanguageStyled";
import { inject, observer } from "mobx-react";
import PokoroPen from "../../common/Images/PokoroPen.png";
import { LineStyle } from "./Styled/SettingStyled";
import { usePenStatus } from "../../nativebridge/PenStatus";
import { customSendSetting } from "../../nativebridge/JsToNative";
import CommonLoading from "../CommonComponent/CommonLoading";
import CommonComfirmDialog from "../CommonComponent/CommonComfirmDialog";
import { Icon } from '@iconify/react';
import {
  NativeToJsBridge,
  NativeToJsEventName,
} from "../../nativebridge/NativeToJs";
// import languageMap from "./Setting";

function ListeningLanguageSetting(props) {
  const languageMap = {
    US: "English",
    KR: "한국어",
    JP: "日本語",
    CN: "简体中文",
    ES: "Español",
    DE: "Deutsch",
    XA: "ٱلسُّعُوْدِيَّة",
  };
  const { intl } = props;
  const history = useHistory();
  const { location } = useLocation();

  const { globalPenStatus, setGlobalPenStatus } = usePenStatus();
  const [loading, setLoading] = React.useState(false);
  const [disconnect, setDisconnect] = React.useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const handleBack = () => {
    history.goBack();
  };

  const onDeviceDisConnected = () => {
    setGlobalPenStatus((prevStatus) => ({
      ...prevStatus, // 현재 상태를 복사
      isConnect: false, // isConnect 속성만 변경
    }));
    setDisconnect(true);
    handleBack();
  };

  useEffect(() => {
    NativeToJsBridge.instance.addEventListener(
      NativeToJsEventName.onDeviceDisConnected,
      async (event) => {
        // const jsonStr = event;
        onDeviceDisConnected();
      }
    );
    NativeToJsBridge.instance.addEventListener(
      NativeToJsEventName.onCustomSendResult,
      async (event) => {
        const jsonStr = event;
        setLoading(false);
      }
    );
    return () => {
      NativeToJsBridge.instance.removeEventListener(
        NativeToJsEventName.onCustomSendResult
      );
      NativeToJsBridge.instance.removeEventListener(
        NativeToJsEventName.onDeviceDisConnected
      );
    };
  }, []);

  const handleLanguageChange = (type, languageCode) => {
    console.log(
      `handleLanguageChange type== ${type},languageCode=${languageCode} `
    );

    let changeMain = false;
    let changeSub = false;

    if (type === "main") {
      if (globalPenStatus.deviceInfo.lang_listening_main !== languageCode)
        changeMain = true;
      setGlobalPenStatus((prevStatus) => ({
        ...prevStatus,
        deviceInfo: {
          ...prevStatus.deviceInfo, // spread the previous deviceInfo object
          lang_listening_main: languageCode, // update lang_main
        },
      }));
    } else if (type === "sub") {
      if (globalPenStatus.deviceInfo.lang_listening_sub !== languageCode)
        changeSub = true;

      setGlobalPenStatus((prevStatus) => ({
        ...prevStatus,
        deviceInfo: {
          ...prevStatus.deviceInfo, // spread the previous deviceInfo object
          lang_listening_sub: languageCode, // update lang_main
        },
      }));
    }

    if (changeMain || changeSub) {
      let lang_main = globalPenStatus.deviceInfo.lang_listening_main;
      let lang_sub = globalPenStatus.deviceInfo.lang_listening_sub;
      if (changeMain) lang_main = languageCode;
      if (changeSub) lang_sub = languageCode;

      const settingData = {
        messageType: "POKORO2_Settings",
        payload: {
          lang_system: globalPenStatus.deviceInfo.lang_system,
          lang_speaking: globalPenStatus.deviceInfo.lang_speaking,
          lang_listening_main: lang_main,
          lang_listening_sub: lang_sub,
        },
      };
      setLoading(true);
      customSendSetting(JSON.stringify(settingData));
    }
  };

  return (
    <>
      <Stack width="100%">
        <TitleBox>
          <Icon icon='material-symbols:arrow-back-ios-rounded' onClick={handleBack}/>
          <SubpageTitle sx={{ background: "unset" }}>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: "bolder" }}>
              {intl.formatMessage({ id: "listening_language_settings" })}{" "} {/* 듣기 언어설정 */}
            </Typography>
            <Typography variant="body2"> 
              {intl.formatMessage({ id: "language_selection_prompt" })}{" "} {/* 포코로가 답변해주는 언어를 선택해주세요. */}
              <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
                mt={2}
                sx={{ color: "#909195", fontSize: "0.75rem" }}
              >
                <ExImage>
                  <img src={PokoroPen} alt="Profile" width="70%" />
                </ExImage>
                <pre>
                  {intl.formatMessage({ id: "language_switch_instruction" })} {/* 선택한 언어는 Fn키를 짧게 눌러 전환합니다. */}
                </pre>{" "}
              </Stack>
            </Typography>
          </SubpageTitle>
        </TitleBox>
        <Stack gap={1} mt={4}>
          <ListTitle>MAIN</ListTitle>
          {Object.keys(languageMap).map((languageCode) => (
            <List>
              <Stack flexDirection='row' justifyContent='space-between'
                key={languageCode}
                selected={globalPenStatus.deviceInfo.lang_listening_main === languageCode} // 선택된 국가 코드 확인
                onClick={() => handleLanguageChange("main", languageCode)} // 국가 코드 설정
                disabled={globalPenStatus.deviceInfo.lang_listening_sub === languageCode}
              >
                <Typography variant="body1">{intl.formatMessage({ id: `${languageCode}` })}</Typography>
                <Typography variant="caption" color='#757575'>{languageMap[languageCode]}{" "}</Typography>
              </Stack>
              {/* 국가 코드에 대응하는 언어 이름 표시 */}
            </List>
          ))}
        </Stack>
        <LineStyle />
        <Stack gap={1} mb={5}>
          <ListTitle>SUB</ListTitle>
          {Object.keys(languageMap).map((languageCode) => (
            <List>
              <Stack flexDirection='row' justifyContent='space-between'
                key={languageCode}
                selected={globalPenStatus.deviceInfo.lang_listening_sub === languageCode} // 선택된 국가 코드 확인
                onClick={() => handleLanguageChange("sub", languageCode)} // 국가 코드 설정
                disabled={globalPenStatus.deviceInfo.lang_listening_main === languageCode}
              >
                <Typography variant="body1">{intl.formatMessage({ id: `${languageCode}` })}</Typography>
                <Typography variant="caption" color='#757575'>{languageMap[languageCode]}{" "}</Typography>
              </Stack>
              {/* 국가 코드에 대응하는 언어 이름 표시 */}
            </List>
          ))}
        </Stack>
      </Stack>
      <CommonComfirmDialog
        open={disconnect}
        title={intl.formatMessage({ id: "disconnect_dialog_Title" })} /* 포코로 연결 종료 */
        contents={intl.formatMessage({ id: "disconnect_dialog_Title" })} /* 포코로 연결 종료 */
        buttonText={intl.formatMessage({ id: "dialog_confirmButton" })}
        onClick={handleBack}
      />
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            zIndex: 9999,
          }}
        >
          <CommonLoading text={intl.formatMessage({ id: "setting_sending" })} /> {/* 설정 정보를 전송 중입니다. */}
        </div>
      )}
    </>
  );
}

export default injectIntl(
  inject("authStore")(observer(ListeningLanguageSetting))
);
